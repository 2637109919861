import {Nodash} from '../nodash/Nodash';

export const UrlHelper = (params: Record<string, unknown>): string => {
  let url = '';
  const reservedKeys: string[] = [''];
  for (const [key, value] of Object.entries(params)) {
    if (reservedKeys.includes(key)) continue;
    if (Nodash.isEmpty(value as string)) continue;

    url = `${url}&${key}=${encodeURIComponent(value as string)}`;
  }

  if (url.startsWith('&')) {
    url = url.replace(/^&/, '?');
  }
  
  return url;
};