// React
import React, {useEffect, useState} from 'react';

// Styles
import './Main.scss';

// Interfaces
import {MainProperties} from './MainInterfaces';

// Components
import Header from '../header/Header';

// Routes
import {useNavigate} from 'react-router-dom';

// Images
import {ReactComponent as Close} from '../../../assets/icons/close.svg';
import {ReactComponent as Group} from '../../../assets/icons/group.svg';
import {ReactComponent as Phone} from '../../../assets/icons/phone.svg';
import {ReactComponent as Email} from '../../../assets/icons/email2.svg';

const Main: React.FC<MainProperties> = (props: MainProperties): JSX.Element => {
  // States
  const [headerLinks, setHeaderLinks] = useState<Array<string>>([]);
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    if (!props.protected) return;

    if (localStorage.getItem('jwt_token')
      && localStorage.getItem('jwt_token') !== ''
      && new Date(parseJwt(localStorage.getItem('jwt_token') ?? '').exp) >= new Date()
    ) return;

    switch (props.header) {
      case 'advisor':
        navigate('/', {replace: true});
        break;
      case 'client':
        navigate('landing', {replace: true});
        break;
      default:
        setHeaderLinks([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleHeaderContent();
  }, [props.header]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const handleHeaderContent = (): void => {
    switch (props.header) {
      case 'advisor':
        setHeaderLinks([]);
        break;
      case 'client':
        setHeaderLinks([
          'Contact',
        ]);
        break;
      default:
        setHeaderLinks([]);
    }
  };

  return (
    <React.Fragment>
      <div id={'main'}>
        {/*HEADER*/}
        <div className={'header-wrapper'}>
          <Header
            header={props.header}
            links={headerLinks}
            path={props.path}
            contactOpen={contactModalOpen}
            handleContactModal={() => setContactModalOpen(true)}
          />
        </div>

        {/*CONTENT*/}
        <div className={'body'}>
          <div className={'content-wrapper'}>
            {props.children}
          </div>

          {/*FOOTER*/}
          {/*<div className={'footer-wrapper'}>*/}
          {/*  <Footer links={footerLinks}/>*/}
          {/*</div>*/}
        </div>

        {/*CONTACT*/}
        {contactModalOpen &&
        /*BACKDROP*/
        <div
          className={'backdrop'}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setContactModalOpen(false);
          }}
        >
          {/*MODAL*/}
          <div
            className={'contact-modal'}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setContactModalOpen(true);
            }}
          >
            {/*CLOSE ICON*/}
            <div
              className={'icon-close'}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setContactModalOpen(false);
              }}
            >
              <Close/>
            </div>

            {/*IMAGE*/}
            <Group/>

            {/*TITLE*/}
            <h2>Heb je vragen?</h2>

            {/*DESCRIPTION*/}
            <p>Neem gerust contact met ons!</p>

            {/*CONTENT*/}
            <div className={'content-wrapper'}>
              {/*MOBILE*/}
              <div className={'details-container'}>
                {/*ICON*/}
                <div className={'icon-wrapper'}>
                  <Phone/>
                </div>

                {/*VALUE*/}
                <p className={'value'}>
                  020 045 04 05
                </p>
              </div>

              {/*EMAIL*/}
              <div className={'details-container'}>
                {/*ICON*/}
                <div className={'icon-wrapper'}>
                  <Email/>
                </div>

                {/*VALUE*/}
                <p className={'value'}>
                  kantoren@xbank.nl
                </p>
              </div>
            </div>
          </div>
        </div>
        }
      </div>


    </React.Fragment>
  );
};

export default Main;