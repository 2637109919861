const SingularPluralList: any = {
    je: 'jullie',
    houd: 'houden',
    mijn: 'ons',
    kunt: 'kunnen',
    kun: 'kunnen'
};

export const SingularToPlural = {
    convert: (string: string) => {

        let isFirstCharacterUpperCase = string.charAt(0) === string.charAt(0).toUpperCase();
    
        if (SingularPluralList.hasOwnProperty(string.toLowerCase())) {
            string = SingularPluralList[string.toLowerCase()];
    
            if (isFirstCharacterUpperCase) string = capitalizeFirstLetter(string);
        }
    
        return string;
    }
}

function capitalizeFirstLetter (string: string): string {
    return string[0].toUpperCase() + string.substring(1);
}