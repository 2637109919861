export const Nodash = {
  // Checks if a value of any type is empty
  isEmpty: (value: string | number | Array<any> | Record<string, unknown>): boolean => {
    // Checks for most empty values, should execute the fastest
    if (!value) return true;

    // Checks for undefined value
    if (typeof value == 'undefined') return true;

    // Checks for empty string
    if (value === '') return true;

    // Checks for empty array
    if (Array.isArray(value) && !value.length) return true;

    // Checks for empty object
    if (typeof value === 'object' && !Object.keys(value).length) return true;

    // Returns false if value not empty
    return false;
  },

  // Will create a shallow clone of an object
  clone: (value: Record<string, unknown>): Record<string, any> => {
    return Object.assign({}, value);
  },

  // Will validate a string based on type: email | password
  validateString: (type: string, value: string) => {
    switch (type) {
      case 'email': {
        return value.match(/([a-zA-Z0-9._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+)/gi);
      }
      case 'password': {
        const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$');
        return pattern.test(value);
      }
      default:
        return true;
    }
  },
};