// React
import React, {useState, useEffect, useRef} from 'react';

// Styles
import './Stepper.scss';

// Icons
import {ReactComponent as Check} from '../../../assets/icons/check.svg';

// Interfaces
import {StepperProperties} from './StepperInterces';

export const Stepper: React.FC<StepperProperties> = ({activeStep, completed, steps}) => {
  // Refs
  const stepperSelector = useRef<HTMLDivElement>(null);

  // States
  const [currentStep, setCurrentStep] = useState<number>(1);

  // Variables
  const progressPerStep = 100 / (steps.map(step => step.title).length - 1);
  const progress = (currentStep - 1) * progressPerStep;

  // Effects
  useEffect(() =>{
    if (!activeStep) return;
    if (activeStep && activeStep <= 0) return;

    setCurrentStep(activeStep ?? 1);
  }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const handleNextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const handlePreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  return (
    <div id={'stepper'}>
      <div className={'stepper stepper-wrapper'}>
        <div className={'stepper-progress'}>
          <div className={'stepper-progress-wrapper'}>
            <div className={'stepper-progress-bar-wrapper'}>
              <div className={'stepper-progress-track'}>
              </div>
              <div
                className={'stepper-progress-bar'}
                style={{width: progress + '%'}}
              />
            </div>
            {steps.map((step, index) => (
              <div className={'step-title'} key={`step-title-${index}`}>
                <div
                  className={`step-title-number  ${currentStep - 1 === index && !completed ? 'step-title-number--active' : currentStep - 1 > index || completed ? 'step-title-number--completed' : ''}`}>
                  {
                    currentStep - 1 > index || completed
                      ?
                      <Check className={'check-mark'}/>
                      :
                      index + 1
                  }
                </div>
                {step.title}
              </div>
            ))}
          </div>
        </div>

        <div className="stepper-selector" ref={stepperSelector}>
          {steps.map((step, index) => (
            <React.Fragment key={`step-selector-${index}`}>
              {currentStep === index + 1 &&
              <div className={'step-wrapper'}>
                <step.element
                  step={index + 1}
                  goNextStep={handleNextStep}
                  goPreviousStep={handlePreviousStep}
                  currentStep={currentStep}
                  isFirst={index === 0}
                  isLast={index === steps.length - 1}
                />
              </div>
              }
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;