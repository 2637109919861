// React
import React, {useEffect} from 'react';

// Styles
import './Snackbar.scss';

// Interfaces
import {SnackbarProperties} from './SnackbarInterfaces';

const Snackbar: React.FC<SnackbarProperties> = (props: SnackbarProperties): JSX.Element => {
  // Effects
  useEffect(() =>{
    if (!props.alert) return;

    setTimeout(() => {
      props.removeAlert();
    }, 5000);
  }, [props.alert]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className={`snackbar snackbar--${props.alert.severity}`}>
        <div className={'snackbar__content'}>
          <i className={'snackbar__icon'}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
            </svg>
          </i>
          <p className={'snackbar__title'}>{props.alert.title}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Snackbar;