// React
import React, {useEffect, useState} from 'react';

// Styles
import './Landing.scss';

// Images
import Phone from '../../../assets/images/phone.png';
import {ReactComponent as Check} from '../../../assets/icons/check.svg';
import {ReactComponent as Click} from '../../../assets/images/click.svg';
import {ReactComponent as Download} from '../../../assets/images/download.svg';
import {ReactComponent as Oversight} from '../../../assets/images/oversight.svg';

// Shared
import Button from '../../../components/controls/button/Button';
import Snackbar from '../../../components/feedback/snackbar/Snackbar';

// Intefaces
import { ValidationInterface} from './LandingInterfaces';
import {Alert} from '../../../components/feedback/snackbar/SnackbarInterfaces';

// Middleware
import {ServiceHelper} from '../../../middleware/serviceHelper/ServiceHelper';
import {APITypes} from '../../../middleware/enums/Enums';

// Routes
import {useNavigate, useLocation} from 'react-router-dom';

const Landing: React.FC = (): JSX.Element => {
  // States
  const [alert, setAlert] = useState<Alert>();
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const location: any = useLocation();
  
  // Effects
  useEffect(() => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('applicationId');

    handleUrlParms();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const handleUrlParms = (): void => {
    let params = new URLSearchParams(window.location.search);

    let completedJourney = location.state && location.state.prevPath && location.state.prevPath === '/options'; // Final screen URL 

    if (!completedJourney) {
      let invitationId = params.get('invId') ?? localStorage.getItem('invitationId') ?? '';
      localStorage.setItem('invitationId', invitationId);
      validateUser({InvitationId: invitationId});
    }
  };

  const validateUser = (body: ValidationInterface) => {
    ServiceHelper
      .post({
        api: APITypes.INVITATIONS,
        url: '/invitations/exchange',
        body: body as unknown as Record<string, string>,
      })
      .then(result => {
        if (!result) {
          setAlert(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
        }

        localStorage.setItem('jwt_token', result?.data?.Token ?? '');
        localStorage.setItem('userId', result?.data?.UserId ?? '');
        localStorage.setItem('applicationId', result?.data?.ApplicationId ?? '');

        setAuthenticated(true);
      })
      .catch((error) => {
        setAlert(
          {
            severity: 'error',
            title:
              error?.response?.data === 'Invalid invitation.'
                ?
                'Oeps! Het lijkt erop dat je uitnodiging is verlopen. Neem contact op met jouw adviseur. '
                :
                'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  return (
    <React.Fragment>
      <div id={'landing'}>
        {/*BANNER*/}
        <div className={'banner'}>
          <div className={'banner-wrapper'}>
            <div className={'banner-content-wrapper'}>
              {/*HEADER*/}
              <h1 className={'banner-header'}>
                Beter inzicht in je netto inkomsten en uitgaven? <span>BEYOND</span> helpt je verder!
              </h1>

              {/*LIST*/}
              <ul className={'banner-list'}>
                <li className={'list-item'}>
                  {/*ICON*/}
                  <Check/>

                  {/*TEXT*/}
                  <p>Koppel jouw bankrekening(en)</p>
                </li>
                <li className={'list-item'}>
                  {/*ICON*/}
                  <Check/>

                  {/*TEXT*/}
                  <p>Ontvang één overzicht met al jouw inkomsten en uitgaven</p>
                </li>
                <li className={'list-item'}>
                  {/*ICON*/}
                  <Check/>

                  {/*TEXT*/}
                  <p>Ontvang een passend financieel advies</p>
                </li>
              </ul>

              {/*BUTTON*/}
              <div className={'button-wrapper'}>
                <Button
                  ariaLabel={'Aan de slag'}
                  form={''}
                  name={''}
                  disabled={!authenticated}
                  variant={'primary'}
                  onClick={() => navigate('/options')}
                >
                  Beginnen
                </Button>
              </div>
            </div>
          </div>

          <div className={'image-wrapper'}>
            <img src={Phone} alt={''}/>
          </div>
        </div>

        {/*CONTENT*/}
        <div className={'content'}>
          {/*HEADER*/}
          <h3 className={'header'}>Hoe werkt het?</h3>

          {/*STEPS*/}
          <div className={'card-container'}>
            {/*CARD*/}
            <div className={'card'}>
              {/*IMAGE*/}
              <div className={'image'}>
                <Click/>
              </div>

              {/*NUMBER*/}
              <h4 className={'header'}>1.</h4>

              {/*HEADER*/}
              <h4 className={'header'}>Verzamel je gegevens</h4>

              {/*DESCRIPTION*/}
              <p className={'description'}>Koppel je bankrekening(en). Je kunt verschillende banken koppelen</p>
            </div>

            {/*LINE*/}
            <div className={'line'}/>

            {/*CARD*/}
            <div className={'card'}>
              {/*IMAGE*/}
              <div className={'image'}>
                <Oversight/>
              </div>

              {/*NUMBER*/}
              <h4 className={'header'}>2.</h4>

              {/*HEADER*/}
              <h4 className={'header'}>Krijg je betaalprofiel</h4>

              {/*DESCRIPTION*/}
              <p className={'description'}>Wij gaan rekenen en binnen enkele seconden
                ontvang je je betaalprofiel</p>
            </div>

            {/*LINE*/}
            <div className={'line'}/>

            {/*CARD*/}
            <div className={'card'}>
              {/*IMAGE*/}
              <div className={'image'}>
                <Download/>
              </div>

              {/*NUMBER*/}
              <h4 className={'header'}>3.</h4>

              {/*HEADER*/}
              <h4 className={'header'}>Download als pdf</h4>

              {/*DESCRIPTION*/}
              <p className={'description'}>Download de pdf en deel deze met je adviseur</p>
            </div>
          </div>
        </div>
      </div>

      {/*SNACKBAR*/}
      {alert &&
      <Snackbar
        alert={alert}
        removeAlert={() => setAlert(undefined)}
      />
      }
    </React.Fragment>
  );
};

export default Landing;