// React
import React, {useEffect, useState} from 'react';

// Styles
import './App.scss';

// Middleware
import {Themes} from './middleware/enums/Enums';

// Routes
import {Router} from './routes/Router';

const App: React.FC = (): JSX.Element => {
  // State
  const [theme, setTheme] = useState<string>();

  // Effects
  useEffect(() =>{
    let loggedInClient = 'default'; // Get logged in client
    let tempTheme: string;

    // Passing through switch to always have a fallback to default theme
    switch (loggedInClient) {
      default:
        tempTheme = Themes.DEFAULT;
    }

    setTheme(tempTheme);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id={'theme'} className={`theme-${theme}`}>
      <div id={'app'}>
        <Router/>
      </div>
    </div>
  );
};

export default App;

