// Created By: Chris
// Created On: 2021/11/11

// React
import React from 'react';

// Styles
import './Progress.scss';

// Interfaces
import {ProgressProperties} from './ProgressInterfaces';

const Progress: React.FC<ProgressProperties> = (props: ProgressProperties): JSX.Element => {
  return (
    <React.Fragment>
      <div className={`${props.className} progress__track`}>
        <div className={'progress__thumb'} style={{width: props.width + '%'}}/>
      </div>
    </React.Fragment>
  );
};

export default Progress;