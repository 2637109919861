import React from 'react';

// Interfaces
import * as INTERFACES from './IconHelperInterfaces';

// Icons
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';

export const IconHelper: React.FC<INTERFACES.IconHelperProperties> = (props: INTERFACES.IconHelperProperties) => {
	//Icons
  const iconList: INTERFACES.IconListInterface = {
		logout: <Logout className={props?.className ?? 'icon'} width={props.size ?? 10} height={props.size ?? 10} title={props?.title ?? 'Afsluiten'}/>,
	};

	return (
		iconList[props.name]
	);
};
