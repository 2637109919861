// Created By: Johan
// Created On: 12/7/2021

// React
import React, { useEffect, useState } from 'react';

// Styles
import './Calculations.scss';

// Images
import { ReactComponent as Salary } from '../../../../../assets/icons/salary.svg';
import { ReactComponent as Home } from '../../../../../assets/icons/home.svg';
import { ReactComponent as Leisure } from '../../../../../assets/icons/leisure.svg';
import ABNA from '../../../../../assets/images/logos/ABNA.jpg';
import INGB from '../../../../../assets/images/logos/INGB.jpg';
import KNAB from '../../../../../assets/images/logos/KNAB.jpg';
import NTSB from '../../../../../assets/images/logos/NTSB.jpg';
import RABO from '../../../../../assets/images/logos/RABO.jpg';
import RBRB from '../../../../../assets/images/logos/RBRB.jpg';
import REVO from '../../../../../assets/images/logos/REVO.jpg';
import SNSB from '../../../../../assets/images/logos/SNSB.png';
import TRIO from '../../../../../assets/images/logos/TRIO.jpg';
import BUNQ from '../../../../../assets/images/logos/BUNQ.jpg';
import DEFAULT from '../../../../../assets/images/logos/default.png';

// Interfaces
import { CalculationsProperties, Budget } from './CalculationsInterfaces';
import { Alert } from '../../../../../components/feedback/snackbar/SnackbarInterfaces';
import { Account } from '../details/DetailsInterfaces';

// Middleware
import { ServiceHelper } from '../../../../../middleware/serviceHelper/ServiceHelper';
import { APITypes, OwnerTypes } from '../../../../../middleware/enums/Enums';

// Shared
import Snackbar from '../../../../../components/feedback/snackbar/Snackbar';
import Loader from '../../../../../components/feedback/loader/Loader';
import Button from '../../../../../components/controls/button/Button';
import { Accordion } from '../../../../../components/container/accordion/Accordion';
import { SingularToPlural } from '../../../../../helpers/singularToPlural';

const Calculations: React.FC<CalculationsProperties> = (props: CalculationsProperties) => {
  // State
  const [budget, setBudget] = useState<Budget>();
  const [snackbarMessage, setSnackbarMessage] = useState<Alert>();
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Array<Account>>();
  const [selectedAccounts, setSelectedAccounts] = useState<Array<string>>([]);
  const [tinkUrl, setTinkUrl] = useState<string>('');
  const [incomeModalOpen, setIncomeModalOpen] = useState<boolean>(false);
  const [expenseModalOpen, setExpenseModalOpen] = useState<boolean>(false);
  const [leisureModalOpen, setLeisureModalOpen] = useState<boolean>(false);
  const [hasPartner, setHasPartner] = useState<boolean>(false);

  const dots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  // Effects
  useEffect(() => {
    setLoading(true);

    getCordBudget(false);

    if (!props.nibud) {
      getAccounts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tinkUrl) return;

    window.location.href = tinkUrl;
    setTinkUrl('');
  }, [tinkUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const getBudget = (): void => {
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .post({
        api: APITypes.RULECUBE,
        url: `/applications/${applicationId}/calculate/RemainingBudget`,
        body: {},
      })
      .then((result) => {
        let tempIncomes = [];
        let tempExpenses = [];
        let tempAssets = [];

        for (let income of result?.data?.result?.Incomes) {
          if (income.MonthlyIncome.Value === 0) continue;
          tempIncomes.push({
            ...income,
            ApplicationID: applicationId,
            Owner: OwnerTypes.Client,
          });
        }
        // Add Owners and ApplicationId keys to each expense
        for (let expense of result?.data?.result?.Expenses) {
          if (expense.Amount === 0) continue;
          tempExpenses.push({
            ...expense,
            ApplicationID: applicationId,
            Owners: [OwnerTypes.Client],
          });
        }

        // Add Owners and ApplicationId keys to each asset
        for (let asset of result?.data?.result?.Assets) {
          if (asset.Deposit === 0) continue;

          tempAssets.push({
            ...asset,
            ApplicationID: applicationId,
            Owners: [OwnerTypes.Client],
          });
        }

        saveAssets(tempAssets as unknown as Record<string, unknown>);
        saveIncomes(tempIncomes as unknown as Record<string, unknown>);
        saveExpenses(tempExpenses as unknown as Record<string, unknown>);

        setTimeout(() => {
          getCordBudget(true);
        }, 3000);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveIncomes = (body: Record<string, unknown>) => {
    ServiceHelper
      .post({
        api: APITypes.CF,
        url: '/incomes',
        body: body,
      })
      .then((result) => {
        return result?.data ?? {};
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const saveExpenses = (body: Record<string, unknown>) => {
    ServiceHelper
      .post({
        api: APITypes.CF,
        url: '/expenses',
        body: body,
      })
      .then((result) => {
        return result?.data ?? {};
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const saveAssets = (body: Record<string, unknown>) => {
    ServiceHelper
      .post({
        api: APITypes.CF,
        url: '/assets',
        body: body,
      })
      .then((result) => {
        return result?.data ?? {};
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const handleDeleteIncomesExpenses = (result: Array<Record<string, unknown>>) => {
    let tempAssets = result[0].Assets ? [...result[0].Assets as Array<Record<string, unknown>>] : [];
    let tempExpenses = result[0].Expenses ? [...result[0].Expenses as Array<Record<string, unknown>>] : [];
    let tempIncomes = result[0].Incomes ? [...result[0].Incomes as Array<Record<string, unknown>>] : [];

    // Strip out only type and value from assets
    for (const asset of tempAssets) {
      deleteAssets(asset?.ID as string ?? '');
    }

    // Strip out only type and value from expenses
    for (const expense of tempExpenses) {
      deleteExpenses(expense?.ID as string ?? '');
    }

    // Strip out only type and value from incomes
    for (const income of tempIncomes) {
      deleteIncomes(income?.ID as string ?? '');
    }

    setTimeout(() => {
      getCordBudget(true);
    }, 3000);
  };

  const deleteIncomes = (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      ServiceHelper
        .delete({
          api: APITypes.CF,
          url: '/incomes',
          id: id,
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          setSnackbarMessage(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
          reject();
        });
    });
  };

  const deleteExpenses = (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      ServiceHelper
        .delete({
          api: APITypes.CF,
          url: '/expenses',
          id: id,
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          setSnackbarMessage(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
          reject();
        });
    });
  };

  const deleteAssets = (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      ServiceHelper
        .delete({
          api: APITypes.CF,
          url: '/assets',
          id: id,
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          setSnackbarMessage(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
          reject();
        });
    });
  };

  const getCordBudget = (init: boolean) => {
    ServiceHelper
      .get({
        api: APITypes.CF,
        url: '/application/true',
      })
      .then((result) => {
        let personLength = result.data[0].Person && result.data[0].Person.length > 0;
        let expensesLength = result.data[0].Expenses && result.data[0].Expenses.length > 0;
        let incomeLength = result.data[0].Incomes && result.data[0].Incomes.length > 0;
        let assetsLength = result.data[0].Assets && result.data[0].Assets.length > 0;

        if (personLength > 0) {
          result.data[0].Person.forEach((person: any) => {
            if (person.Type === 'Partner') {
              setHasPartner(true);
            }
          });
        }

        if (expensesLength || incomeLength || assetsLength) {
          if (!init && !props.nibud) {
            handleDeleteIncomesExpenses(result?.data);
            return;
          }

          scrubData(result?.data ?? {});
          return;
        }

        if (!props.nibud) {
          getBudget();
        }
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scrubData = (result: Array<Record<string, unknown>>) => {
    // Strip out only incomes, expenses and assets
    let tempAssets = result[0].Assets ? [...result[0].Assets as Array<Record<string, unknown>>] : [];
    let tempExpenses = result[0].Expenses ? [...result[0].Expenses as Array<Record<string, unknown>>] : [];
    let tempIncomes = result[0].Incomes ? [...result[0].Incomes as Array<Record<string, unknown>>] : [];
    let filteredAssets = [];
    let filteredExpenses = [];
    let filteredLeisure = [];
    let filteredIncomes = [];
    let incomesTotal: number = 0;
    let expensesTotal: number = 0;
    let assetsTotal: number = 0;
    let leisureTotal: number = 0;
    let tempEmployedCount = 1;
    // Strip out only type and value from assets
    for (const asset of tempAssets) {
      if (asset.Value === 0) continue;

      let tempAsset;

      // Create label based on type
      switch (asset.Type) {
        case 'SavingsAccount':
          tempAsset = { id: asset.ID, label: 'Sparen', value: asset.Value };
          break;
        case 'MutualFund':
          tempAsset = { id: asset.ID, label: 'Beleggen', value: asset.Value };
          break;
        default:
          continue;
      }

      filteredAssets.push(tempAsset);
      let amount = asset.Value as number;
      assetsTotal = assetsTotal + amount;
    }

    // Strip out only type and value from expenses
    for (const expense of tempExpenses) {
      if (expense.Amount === 0) continue;

      let tempExpense;

      // Create label based on type
      switch (expense.Type) {
        case 'UtilityCosts':
          tempExpense = { id: expense.ID, label: 'Woonlasten', value: expense.Amount, tooltip: 'Lokale lasten, energie, gas en water' };
          break;
        case 'TelecomCredit':
          tempExpense = { id: expense.ID, label: 'Telefoon, televisie en internet', value: expense.Amount };
          break;
        case 'Insurances':
          tempExpense = { id: expense.ID, label: 'Verzekeringen', value: expense.Amount };
          break;
        case 'Vervoer':
          tempExpense = { id: expense.ID, label: 'Reiskosten', value: expense.Amount };
          break;
        case 'BoodschappenHuishoudgeld':
          tempExpense = { id: expense.ID, label: 'Voeding', value: expense.Amount };
          break;
        case 'HouseholdExpense':
          tempExpense = {
            id: expense.ID,
            label: 'Gezondheid, verzorging en huishoudelijke uitgaven',
            value: expense.Amount,
          };
          break;
        case 'Subscriptions':
          tempExpense = { id: expense.ID, label: 'Abonnementen en contributies', value: expense.Amount };
          break;
        case 'OverigeVasteLasten':
          tempExpense = { id: expense.ID, label: 'Persoonlijk onvermijdbare lasten', value: expense.Amount, tooltip: 'Studiekosten, afbetalingen, alimentatie etc.' };
          break;
        case 'OnregelmatigeUitgaven':
          tempExpense = { id: expense.ID, label: 'Overige onregelmatig lasten', value: expense.Amount, tooltip: 'Overige uitgaven, huisdieren en onderhoud' };
          break;
        case 'Vakantie/vrije tijd':
          filteredLeisure.push({ id: expense.ID, label: 'Vakantie vrije tijd', value: expense.Amount });

          let leisureAmount1 = expense.Amount as number;
          leisureTotal = leisureTotal + leisureAmount1;
          break;
        case 'Vakantievrijetijd':
          filteredLeisure.push({ id: expense.ID, label: 'Vakantie vrije tijd', value: expense.Amount });

          let leisureAmount2 = expense.Amount as number;
          leisureTotal = leisureTotal + leisureAmount2;
          break;
        case 'Woning':
          tempExpense = { id: expense.ID, label: 'Hypotheek', value: expense.Amount };
          break;
        case 'Rent':
          tempExpense = { id: expense.ID, label: 'Huur', value: expense.Amount };
          break;
        case 'Miscellaneous':
          tempExpense = { id: expense.ID, label: 'Woonverzekeringen', value: expense.Amount };
          break;
        case 'InventoryHome':
          tempExpense = { id: expense.ID, label: 'Onderhoud', value: expense.Amount, tooltip: 'Reserveringsuitgaven voor onderhoud van je woning' };
          break;
        default:
          continue;
      }

      if (tempExpense) {
        filteredExpenses.push(tempExpense);
        let amount = expense.Amount as number;

        expensesTotal = expensesTotal + amount;
      }
    }

    // Strip out only type and value from incomes
    for (const income of tempIncomes) {
      let tempMonthlyIncome: Record<string, unknown> = income.MonthlyIncome as Record<string, unknown>;

      if (tempMonthlyIncome?.Value === 0) continue;

      let tempIncome;

      // Create label based on type
      switch (income.Type) {
        case 'Employed':
          tempIncome = { id: income.ID, label: `Inkomen ${tempEmployedCount}`, value: tempMonthlyIncome?.Value };
          tempEmployedCount = tempEmployedCount + 1;

          const amount = tempMonthlyIncome?.Value as number;
          incomesTotal = incomesTotal + amount;
          filteredIncomes.push(tempIncome);
          break;
        case 'OtherTaxableIncome':
          tempIncome = { id: income.ID, label: 'Onregelmatig inkomen', value: tempMonthlyIncome?.Value };
          break;
        case 'Permanent':
          tempIncome = { id: income.ID, label: 'Je inkomen  (NBI)', value: tempMonthlyIncome?.Value };
          break;
        case 'Allowance':
          tempIncome = { id: income.ID, label: 'Consumptief besteedbaar inkomen', value: tempMonthlyIncome?.Value };
          break;
        case 'OtherNonTaxableIncome':
          tempIncome = { id: income.ID, label: 'Werkelijk besteedbaar inkomen', value: tempMonthlyIncome?.Value };
          break;
        default:
      }
    }

    setBudget({
      assetTotal: assetsTotal,
      expensesTotal: expensesTotal,
      incomesTotal: incomesTotal,
      leisureTotal: leisureTotal,
      netto: (incomesTotal - (expensesTotal + leisureTotal)),
      incomes: filteredIncomes,
      expenses: filteredExpenses,
      assets: filteredAssets,
      leisure: filteredLeisure,
    });
  };

  const generateTinkUrl = (): void => {
    ServiceHelper
      .post({
        //body: {Provider: 'Tink'},
        body: { Provider: 'Tink' },
        api: APITypes.IAM,
        url: '/OAuth/generateLoginUrl',
      })
      .then(result => {
        setTinkUrl(result.data);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const getAccounts = (): number | void => {
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .get({
        api: APITypes.PSD2,
        url: `/applications/${applicationId}/bankAccounts`,
      })
      .then(result => {
        setAccounts(result?.data ?? []);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const handleAccountSelect = (id: string): void => {
    let tempAccounts = [...selectedAccounts];

    if (tempAccounts.includes(id)) {
      let filteredAccounts = tempAccounts.filter(tempAccount => tempAccount !== id);
      setSelectedAccounts(filteredAccounts);
      return;
    }

    tempAccounts.push(id);
    setSelectedAccounts(tempAccounts);
  };

  const handleAccountIcon = (iban: string): string => {
    let image;
    let banks = [
      {
        code: 'ABNA',
        image: ABNA,
      },
      {
        code: 'INGB',
        image: INGB,
      },
      {
        code: 'KNAB',
        image: KNAB,
      },
      {
        code: 'NTSB',
        image: NTSB,
      },
      {
        code: 'RABO',
        image: RABO,
      },
      {
        code: 'RBRB',
        image: RBRB,
      },
      {
        code: 'REVO',
        image: REVO,
      },
      {
        code: 'SNSB',
        image: SNSB,
      },
      {
        code: 'TRIO',
        image: TRIO,
      },
      {
        code: 'BUNQ',
        image: BUNQ,
      },
    ];

    for (let bank of banks) {
      if (iban.includes(bank.code)) {
        image = bank.image;
      }
    }

    return `url(${image ?? DEFAULT})`;
  };

  const showPluralWordIfApplicable = (string: string): string => {
    return hasPartner ? SingularToPlural.convert(string) : string;
  }

  return (
    <React.Fragment>
      <div className={`calculations${loading ? ' calculations__disable-overflow' : ''}`}>
        <div className={'content'}>
          {/*HEADER*/}
          <h3 className={'header'}>Bekijk {showPluralWordIfApplicable("je")} inzicht</h3>

          <div className={'wrapper'}>
            {/*CONTAINER CALCULATIONS*/}
            <div className={'container container__calculations'}>
              {/*HEADER*/}
              <h4 className={'header'}>Wat {showPluralWordIfApplicable("houd")} {showPluralWordIfApplicable("je")} gemiddeld over?</h4>

              {budget && Object.keys(budget).length > 0
                ?
                <React.Fragment>
                  {/*SECTION 1*/}
                  <div className={'section1'}>
                    {/*NETTO*/}
                    <h2 className={'netto'}>{'€ '}{budget?.netto.toString() ?? '0'} <span>netto per maand</span></h2>
                  </div>

                  {/*SECTION 2*/}
                  <div className={'section2'}>
                    {/*INCOMES*/}
                    <Accordion
                      header={
                        <div className={'accordion-header'}>
                          {/*ICON*/}
                          <div className={'icon icon__income'}>
                            <Salary />
                          </div>

                          <div className={'accordion-header-container'}>
                            {/*TITLE*/}
                            <p>{showPluralWordIfApplicable("Je")} inkomen</p>

                            {/*TOTAL*/}
                            <p><span>+</span>{`€ ${budget?.incomesTotal ?? '0'}`}</p>
                          </div>
                        </div>
                      }
                      onHeaderClick={() => setIncomeModalOpen(!incomeModalOpen)}
                      open={incomeModalOpen}
                    >
                      {budget?.incomes && budget.incomes.map((income, index) => (
                        <div className={'income'} key={`income-${index}`}>
                          <p className={'label'}>{`${income?.label}` ?? ''}</p>
                          <p className={'value'}><span>+</span>{`€ ${income?.value} per maand` ?? '0 per maand'}</p>
                        </div>
                      ))}
                    </Accordion>

                    {/*FIXED EXPENSES*/}
                    <Accordion
                      header={
                        <div className={'accordion-header'}>
                          {/*ICON*/}
                          <div className={'icon icon__expenses'}>
                            <Home />
                          </div>

                          <div className={'accordion-header-container'}>
                            {/*TITLE*/}
                            <p>Vaste en essentiële lasten</p>

                            {/*TOTAL*/}
                            <p><span>-</span>{`€ ${budget?.expensesTotal ?? '0'}`}</p>
                          </div>
                        </div>
                      }
                      onHeaderClick={() => setExpenseModalOpen(!expenseModalOpen)}
                      open={expenseModalOpen}
                    >
                      {budget?.expenses && budget.expenses.map((expense, index) => (
                        <div className={'expense'} key={`expense-${index}`}>
                          {expense.tooltip ?
                            <p className={'tooltip'}>{`${expense?.label}` ?? ''}
                              <span className={'tooltiptext'}>{`${expense?.tooltip}` ?? ''}</span>
                            </p> :
                            <p className={'label'}>{`${expense?.label}` ?? ''}</p>
                          }
                          <p className={'value'}><span>-</span>{`€ ${expense?.value} per maand` ?? '0 per maand'}</p>
                        </div>
                      ))}
                    </Accordion>

                    {/*LEISURE EXPENSES*/}
                    <Accordion
                      header={
                        <div className={'accordion-header'}>
                          {/*ICON*/}
                          <div className={'icon icon__leisure'}>
                            <Leisure />
                          </div>

                          <div className={'accordion-header-container'}>
                            {/*TITLE*/}
                            <p>Vrijetijdsuitgaven</p>

                            {/*TOTAL*/}
                            <p><span>-</span>{`€ ${budget?.leisureTotal ?? '0'}`}</p>
                          </div>
                        </div>
                      }
                      onHeaderClick={() => setLeisureModalOpen(!leisureModalOpen)}
                      open={leisureModalOpen}
                    >
                      {budget?.leisure && budget.leisure.map((leisure, index) => (
                        <div className={'leisure'} key={`leisure-${index}`}>
                          <p className={'label'}>{`${leisure?.label}` ?? ''}</p>
                          <p className={'value'}><span>-</span>{`€ ${leisure?.value} per maand` ?? '0 per maand'}</p>
                        </div>
                      ))}
                    </Accordion>
                  </div>

                  {/*SECTION 3*/}
                  <div className={'section3'}>
                    <p className={'header'}>Wat {showPluralWordIfApplicable("houd")} {showPluralWordIfApplicable("je")} netto over?</p>
                    <p className={'value'}>
                      <span>{budget?.netto >= 0 ? '+' : '-'}</span>{`€ ${Math.abs(budget?.netto ?? 0)} per maand`}</p>
                  </div>

                  {/*ACTIONS*/}
                  <div className={'actions'}>
                    <div className={'container-button'}>
                      <Button
                        ariaLabel={'next-step'}
                        form={''}
                        name={'next-step'}
                        variant={'primary'}
                        onClick={() => props.goNextStep()}
                      >
                        Volgende stap
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
                :
                <div className={'container-loader'}>
                  <div className={'loader__spinner'}>
                    {
                      dots.map((dot, index) => (
                        <span key={index} className={`dot__${index}`} />
                      ))
                    }
                  </div>
                </div>
              }
            </div>

            {/*CONTAINER ACCOUNTS*/}
            <div className={'container container__accounts'}>
              {/*HEADER*/}
              <h4 className={'header'}>
                {props.nibud ? 'Toch kijken naar ' + showPluralWordIfApplicable("je") + ' persoonlijke betaalprofiel?' : 'Opgehaalde rekeningen'}
              </h4>

              {/*SUB HEADER - NIBUD*/}
              {props.nibud &&
                <p>Klik hier om alsnog {showPluralWordIfApplicable("je")} bankrekening(en) toe te voegen.</p>
              }

              {/*SECTION 1*/}
              {!props.nibud &&
                <div className={'section1'}>
                  {accounts && accounts.length > 0 && accounts.map((account, index) => (
                    <div
                      className={`row-account${selectedAccounts.includes(account.Id) ? ' row-account--selected' : ''}`}
                      key={`account-row-${index}`}
                    >
                      {/*ACCOUNT*/}
                      <div className={'account'}>
                        {/*ICON*/}
                        <div
                          className={'icon'}
                          style={{
                            backgroundImage: handleAccountIcon(account.Iban ?? ''),
                          }}
                        />
                      </div>

                      {/*ACCOUNT DETAILS*/}
                      <div className={'text'}>
                        {/*ACCOUNT NAME*/}
                        <p className={'name'}>{`Rekening ${index + 1}`}</p>

                        {/*IBAN*/}
                        <p className={'iban'}>{account.Iban ?? ''}</p>
                      </div>
                    </div>
                  ))}
                </div>
              }

              {/*SECTION 2*/}
              {!props.nibud &&
                <div className={'section2'}>
                  {/*ACTIONS*/}
                  <div className={'actions'}>
                    {/*ADD MORE LINK*/}
                    <p className={'add'} onClick={() => generateTinkUrl()}>+Rekening(en) toevoegen</p>
                  </div>
                </div>
              }

              {/*ACTIONS*/}
              {props.nibud &&
                <Button
                  ariaLabel={''}
                  form={''}
                  name={''}
                  variant={'primary'}
                  onClick={() => generateTinkUrl()}
                >
                  Koppel rekening(en)
                </Button>
              }
            </div>
          </div>
        </div>
      </div>

      {/*LOADER*/}
      {loading &&
        <Loader
          text={'Een momentje...'}
        />
      }

      {/*SNACKBAR*/}
      {snackbarMessage &&
        <Snackbar
          alert={snackbarMessage}
          removeAlert={() => setSnackbarMessage(undefined)}
        />
      }

    </React.Fragment>
  );
};

export default Calculations;
