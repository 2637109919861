// React
import { createRoot } from 'react-dom/client';

// Components
import App from './App';

// Constants
const container = document.getElementById('root');
const root = createRoot(container as any);

root.render(<App/>);
