// React
import React, {useEffect, useState} from 'react';

// Interfaces
import {ButtonProperties} from './ButtonInterface';

// Middleware
import StyleHelper from '../../../middleware/styleHelper/StyleHelper';

// Styles
import './Button.scss';

const Button: React.FC<ButtonProperties> = (props: ButtonProperties): JSX.Element => {
  // State
  const [buttonStyles, setButtonStyles] = useState({
    block: 'button',
    elements: {
      variant: props.variant,
    },
    modifiers: {
      disabled: props?.disabled ? 'disabled' : '',
    },
  });

  useEffect(() =>{
    setButtonStyles({...buttonStyles, modifiers: {...buttonStyles, disabled: props?.disabled ? 'disabled' : ''}});
  }, [props.disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div id={'button'}>
        <button
          aria-label={props?.ariaLabel}
          aria-disabled={props?.disabled ?? false}
          autoFocus={props?.autoFocus ?? false}
          className={`${props?.className ? props.className + ' ' : ''}${StyleHelper(buttonStyles)}`}
          form={props?.form}
          name={props?.name}
          tabIndex={props?.tabIndex ?? undefined}
          type={'button'}
          value={props?.value}
          onKeyUp={props?.onKeyUp ?? undefined}
          onKeyDown={props?.onKeyDown ?? undefined}
          onClick={!props.disabled ? props?.onClick ?? undefined : undefined}
        >
          {props.children}
        </button>
      </div>
    </React.Fragment>
  );
};

export default Button;
