// React
import React, {useState} from 'react';

// Styles
import './Options.scss';

// Interfaces
import {OptionsProperties} from './OptionsInterfaces';

// Shared
import Stepper from '../../../components/container/stepper/Stepper';

// Pages
import Details from './steps/details/Details';
import Calculations from './steps/calculations/Calculations';
import Download from './steps/download/Download';
import { useGlobalState } from '../../../middleware/hooks/Hooks';
import { GlobalState } from '../../../middleware/enums/Enums';

const Options: React.FC<OptionsProperties> = (props: OptionsProperties): JSX.Element => {
  // States
  const [completed, setCompleted] = useState<boolean>(false);
  const [nibud, setNibud] = useState<boolean>(false);
  const [hasPartner] = useGlobalState(GlobalState.HAS_PARTNER);
  return (
    <React.Fragment>
      <div id={'options'}>
        <Stepper
          completed={completed}
          steps={[
            {
              title: `Verzamel ${hasPartner ? 'jullie' : 'je'} gegevens`,
              element: stepProps =>
                <Details
                {...stepProps}
                  showAccounts={props?.showAccounts ?? false}
                  onNibudComplete={() => setNibud(true)}
                />,
            },
            {
              title: `Bekijk ${hasPartner ? 'jullie' : 'je'} inzicht`,
              element: stepProps =>
                <Calculations
                  {...stepProps}
                  nibud={nibud}
                />,
            },
            {
              title: 'Download pdf',
              element: stepProps =>
                <Download
                  {...stepProps}
                  completed={() => setCompleted(true)}
                />,
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default Options;