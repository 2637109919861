// React
import React from 'react';

// Styles
import './Success.scss';

// Interfaces
import {SuccessProperties} from './SuccessInterfaces';

// Images
import {ReactComponent as Letter} from '../../../../assets/images/letter.svg';
import {ReactComponent as CheckMark} from '../../../../assets/images/checkmark.svg';
import Button from '../../../../components/controls/button/Button';

const Success: React.FC<SuccessProperties> = (props: SuccessProperties): JSX.Element => {
  return (
    <React.Fragment>
      <div id={'success'}>
        {/*IMAGE*/}
        <div className={'image-letter'}>
          <Letter/>
        </div>

        {/*HEADER*/}
        <h1 className={'header'}>Gelukt!</h1>

        {/*SUBHEADER*/}
        <h4 className={'subheader'}>De klant heeft per e-mail een uitnodiging ontvangen. Wanneer de flow is voltooid, krijgt u een
          bevestiging.</h4>

        {/*SUMMARY BOX*/}
        <div className={'summary'}>
          {/*HEADER*/}
          <div className={'container-header'}>
            <h4>Uitgenodigde klanten</h4>
          </div>

          {/*EMAIL LIST*/}
          <div className={'container-emails'}>
            {props.emails && props.emails.length > 0 && props.emails.map((email, index) => (
              /*EMAIL ROW*/
              <div className={'row-email'} key={`email-row-${index}`}>
                {/*IMAGE*/}
                <div className={'container-image'}>
                  <CheckMark/>
                </div>

                {/*EMAIL*/}
                <p>{email}</p>
              </div>
            ))}

          </div>

          {/*BUTTON - INVITE MORE*/}
          <div className={'button-wrapper'}>
            <Button
              ariaLabel={'login-button-login'}
              form={'login'}
              name={'button-login'}
              variant={'primary'}
              onClick={() => props.handleInviteMore()}
            >
              Een andere klant uitnodigen
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Success;