import React, {useEffect} from 'react';

export const useClickAway = (ref: React.RefObject<HTMLDivElement | undefined>, callback: () => void) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event?.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }); // eslint-disable-line react-hooks/exhaustive-deps
};
