// React
import React, {useEffect, useState} from 'react';

// Styles
import './Invite.scss';

// Interfaces
import {InviteDetails} from './InviteInterfaces';

// Pages
import Success from './success/Success';

// Shared
import Input from '../../../components/controls/input/Input';
import Button from '../../../components/controls/button/Button';
import Loader from '../../../components/feedback/loader/Loader';
import Snackbar from '../../../components/feedback/snackbar/Snackbar';
import {Alert} from '../../../components/feedback/snackbar/SnackbarInterfaces';

// Middleware
import {ServiceHelper} from '../../../middleware/serviceHelper/ServiceHelper';
import {APITypes} from '../../../middleware/enums/Enums';

// Routes
import {useLocation} from 'react-router-dom';

const Invite: React.FC = (): JSX.Element => {
  // States
  const [inviteDetails, setInviteDetails] = useState<InviteDetails>({email: '', userId: ''});
  const [inviteState, setInviteState] = useState<string>('invite');
  const [emails, setEmails] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert>();

  // Hooks
  const {state} = useLocation();

  // Effects
  useEffect(() => {
    if (!state) return;

    let tempState: Record<string, string> = state as Record<string, string>;

    setInviteDetails({...inviteDetails, userId: tempState?.userId ?? ''});
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const handleInvite = (): void => {
    if (!validateEmail(inviteDetails.email)) {
      setAlert(
        {
          severity: 'warning',
          title: 'Vul een geldig e-mailadres in',
        },
      );

      return;
    }

 
    setLoading(true);
    ServiceHelper
      .post({
        api: APITypes.INVITATIONS,
        url: '/invitations',
        body: inviteDetails as unknown as Record<string, unknown>,
      })
      .then(result => {
        if (!result) {
          setAlert(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
        }

        switch (result.status) {
          case 204:
            let tempEmails = [...emails];

            tempEmails.push(inviteDetails.email);
            setEmails(tempEmails);
            setInviteState('success');
            break;
          default:
        }
      })
      .catch((error) => {
        setAlert(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEmail = (email: string): boolean => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  return (
    <React.Fragment>
      <div id={'invite'}>
        {/*INVITE*/}
        {inviteState && inviteState === 'invite' &&
        <React.Fragment>
          {/*HEADER*/}
          <h2 className={'header'}>Klant uitnodigen</h2>

          {/*SUBHEADER*/}
          <h4 className={'subheader'}>Vul hier het e-mailadres van de klant in die je wilt uitnodigen.</h4>

          {/*INVITE BOX*/}
          <form name={'invite'}>
            {/*EMAIL*/}
            <div className={'field-wrapper'}>
              {/*LABEL*/}
              <p className={'label'}>E-mailadres</p>

              {/*INPUT*/}
              <Input
                ariaLabel={'invite-email'}
                form={'invite'}
                name={'username'}
                placeholder={'klant@voorbeeld.nl'}
                tabIndex={1}
                value={inviteDetails?.email ?? ''}
                onChange={(event) => setInviteDetails({...inviteDetails, email: event.target.value})}
              />
            </div>

            {/*BUTTON - INVITE*/}
            <div className={'button-wrapper'}>
              <Button
                ariaLabel={'invite-button-invite'}
                form={'invite'}
                name={'button-invite'}
                tabIndex={1}
                variant={'primary'}
                onClick={() => handleInvite()}
                onKeyUp={(event) => event.key === 'Enter' ? handleInvite() : null}
              >
                Stuur uitnodiging
              </Button>
            </div>
          </form>
        </React.Fragment>
        }

        {/*SUCCESS*/}
        {
          inviteState && inviteState === 'success' &&
          <React.Fragment>
            <Success
              emails={emails}
              handleInviteMore={() => setInviteState('invite')}
            />
          </React.Fragment>
        }
      </div>

      {/*LOADER*/}
      {loading &&
      <Loader
        text={'Een momentje...'}
      />
      }

      {/*SNACKBAR*/}
      {alert &&
      <Snackbar
        alert={alert}
        removeAlert={() => setAlert(undefined)}
      />
      }
    </React.Fragment>
  );
};

export default Invite;