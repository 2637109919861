// Created By: Chris
// Created On: 2021/11/11

// React
import React, {useEffect, useState} from 'react';

// Middleware
import {InputTypes} from '../../../middleware/enums/Enums';
import StyleHelper from '../../../middleware/styleHelper/StyleHelper';

// Styles
import './Checkbox.scss';

// Interfaces
import {CheckboxProperties} from './CheckboxInterfaces';
import {StyleHelperProperties} from '../../../middleware/styleHelper/StyleHelperInterfaces';

const Checkbox: React.FC<CheckboxProperties> = (props: CheckboxProperties): JSX.Element => {
  // States
  const [inputStyles, setInputStyles] = useState<StyleHelperProperties>({
    block: 'checkbox',
    elements: {},
    modifiers: {
      checked: props.checked ? 'checked' : '',
      disabled: props.disabled ? 'disabled' : '',
    },
  });

  // Effects
  useEffect(() =>{
    setInputStyles({
      ...inputStyles,
      modifiers: {
        ...inputStyles.modifiers,
        checked: props.checked ? 'checked' : '',
        disabled: props.disabled ? 'disabled' : '',
      },
    });
  }, [props?.checked, props?.disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div
        className={StyleHelper(inputStyles)}
        onClick={!props?.disabled ? (event) => {
          props?.onClick(event);
        } : undefined}
      >
        {/*INPUT*/}
        <input
          aria-label={props?.ariaLabel}
          aria-disabled={props?.disabled ?? false}
          form={props?.form}
          name={props?.name}
          checked={props.checked}
          type={InputTypes.CHECKBOX}
          onChange={() => null}
        />

        {/*CHECK ICON*/}
        <span className="checkmark"/>
      </div>
    </React.Fragment>
  );
};

export default Checkbox;