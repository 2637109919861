// Interfaces
import {StyleConstructorParams, StyleHelperProperties} from './StyleHelperInterfaces';

const constructStyles = (params: StyleHelperProperties): string => {
  let result = '';

  if (params.elements && Object.keys(params.elements).length > 0) {
    let tempParams: StyleConstructorParams = {
      ...params,
      type: 'element',
    };

    result += constructStyleString(tempParams);
  }

  if (params.modifiers && Object.keys(params.modifiers).length > 0) {
    let tempParams: StyleConstructorParams = {
      ...params,
      type: 'modifier',
    };

    result += constructStyleString(tempParams);
  }

  return result;
};

const constructStyleString = (params: StyleConstructorParams): string => {
  let tempObject: Record<string, string | undefined> | undefined = params.type === 'element' ? params.elements : params.modifiers;
  let result = '';

  if (!tempObject) return result;

  for (const value of Object.entries(tempObject)) {
    if (!value[1] || value[1] === '') continue;

    if (params.type === 'element') {
      result += `${params.block ?? 'unknown'}__${value[1] ?? 'unknown'} `;
      continue;
    }

    if (!params.elements || Object.keys(params.elements).length === 0) {
      result += `${params.block ?? 'unknown'}--${value[1] ?? 'unknown'} `;
      continue;
    }

    for (let element of Object.entries(params.elements)) {
      result += `${params.block ?? 'unknown'}__${element[1]}--${value[1] ?? 'unknown'} `;
    }
  }

  return result;
};

const StyleHelper = (props: StyleHelperProperties): string => {
  return `${props?.block ?? 'unknown'} ${constructStyles(props)}`;
};

export default StyleHelper;