// React
import React, {useEffect, useState, useRef} from 'react';

// Interfaces
import {InputProperties} from './InputInterface';

// Middleware
import StyleHelper from '../../../middleware/styleHelper/StyleHelper';

// Custom Hooks

// Styles
import './Input.scss';
import {useClickAway} from '../../../middleware/hooks/Hooks';

const Input: React.FC<InputProperties> = (props: InputProperties): JSX.Element => {
  // Refs
  const ref: React.RefObject<HTMLDivElement | undefined> = useRef();

  // States
  const [selected, setSelected] = useState(false);
  const [inputStyles, setInputStyles] = useState({
    block: 'input-wrapper',
    modifiers: {
      selected: selected ? 'selected' : '',
      error: props?.error ? 'error' : '',
    },
  });

  // Effects
  useEffect(() => {
    setInputStyles({
      ...inputStyles,
      modifiers: {
        ...inputStyles.modifiers,
        selected: selected ? 'selected' : '',
      },
    });
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.error) return;

    setInputStyles({...inputStyles, modifiers: {...inputStyles.modifiers, error: props?.error ? 'error' : ''}});
  }, [props.error]); // eslint-disable-line react-hooks/exhaustive-deps

  // Custom hooks
  useClickAway(ref, () => {
    setSelected(false);
  });

  return (
    <React.Fragment>
      <div id={'input'}>
        <div
          ref={ref as React.RefObject<HTMLDivElement>}
          className={StyleHelper(inputStyles)}
          onClick={() => {
            setSelected(true);
          }}
        >
          {props.startAdornment &&
          <div className={'adornment adornment__start'}>
            {props.startAdornment ?? ''}
          </div>
          }

          {/*INPUT*/}
          <input
            aria-label={props?.ariaLabel}
            aria-disabled={props?.disabled ?? false}
            className={`${props?.className ?? ''}input${props.disabled ? ' input__disabled' : ''}`}
            form={props?.form}
            max={props?.max ?? undefined}
            maxLength={props?.maxLength ?? 255}
            min={props?.min ?? undefined}
            minLength={props?.minLength ?? undefined}
            name={props?.name}
            placeholder={props?.placeholder ?? ''}
            readOnly={props?.readOnly ?? false}
            required={props?.required ?? false}
            tabIndex={props?.tabIndex ?? undefined}
            type={props?.type ?? 'text'}
            value={props?.value}
            onBlur={props?.onBlur ?? undefined}
            onChange={!props?.disabled ? props?.onChange : undefined}
            onFocus={props?.onFocus ?? undefined}
            onKeyUp={props?.onKeyUp ?? undefined}
            onKeyDown={props?.onKeyDown ?? undefined}
          />

          {props.endAdornment &&
          <div className={'adornment adornment__end'}>
            {props.endAdornment ?? ''}
          </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Input;