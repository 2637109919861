// React
import React, {useState} from 'react';

// Styles
import './Header.scss';

// Images
import Logo from '../../../assets/images/logo-beyond.png';

// Interfaces
import {HeaderProperties} from './HeaderInterfaces';

// Middleware
import {IconHelper} from '../../../middleware/iconHelper/IconHelper';

// ROUTES
import {useNavigate} from 'react-router-dom';

const Header: React.FC<HeaderProperties> = (props: HeaderProperties): JSX.Element => {
  // State
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();

  // Handlers
  const handleNavigation = (link: string) => {
    if (!link) return;

    if (menuOpen) {
      setMenuOpen(false);
    }

    switch (link) {
      case 'Home':
        if (props.header === 'advisor') {
          navigate('/invite');
        } else {
          navigate(`/landing?invId=${localStorage.getItem('invitationId')}`);
        }
        break;
      case 'Contact':
        props.handleContactModal();
        break;
      default:
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('invitationId');

    if (props.header === 'advisor') {
      navigate('/', {replace: true});
      return;
    }

    navigate(`/landing`, {replace: true});
  };

  return (
    <React.Fragment>
      <div id={'header'}>
        {/*MENU*/}
        <div className={'menu'}>
          {/*LOGO*/}
          <img
            className={'logo'}
            alt={'Beyond Logo'}
            src={Logo}
          />

          {/*NAVIGATION LINKS*/}
          <div className={'navigation-wrapper'}>
            {props.links && props.links.map((link, index) => (
              /*LINK*/
              <button
                key={`link-${index}`}
                onClick={() => handleNavigation(link)}
              >
                {link}
              </button>
            ))}
          </div>

          {/*LOGOUT*/}
          {props.header !== 'login' && props.path !== 'loading' &&
          <button
            className={'logout'}
            onClick={() => handleLogout()}
          >
            {/*ICON*/}
            <div className={'iconWrapper'}>
              {IconHelper({name: 'logout', size: 17.5})}
            </div>

            {/*TEXT*/}
            <span>Afsluiten</span>
          </button>
          }

          {/*HAMBURGER BUTTON*/}
          {props.header !== 'login' && !menuOpen
            ?
            <div className={`hamburger`} onClick={() => setMenuOpen(true)}>
              <span className={'line'}/>
              <span className={'line'}/>
              <span className={'line'}/>
            </div>
            :
            props.header !== 'login' &&
            <React.Fragment>
              {/*CLOSE ICON*/}
              <div className={`close`} onClick={() => setMenuOpen(false)}>
                <span className={'line'}/>
                <span className={'line'}/>
              </div>

              {/*MENU*/}
              <div className={'overlay'}>
                {props.links && props.links.map((link, index) => (
                  /*LINK*/
                  <button
                    key={`link-${index}`}
                    onClick={() => handleNavigation(link)}
                  >
                    {link}
                  </button>
                ))}

                {props.header !== 'login' &&
                <button
                  className={'logout-hamburger'}
                  onClick={() => {
                    setMenuOpen(false);
                    handleLogout();
                  }}
                >
                  {/*ICON*/}
                  <div className={'iconWrapper'}>
                    {IconHelper({name: 'logout', size: 22.5})}
                  </div>

                  {/*TEXT*/}
                  <span>Afsluiten</span>
                </button>
                }
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;