// React
import React, {PropsWithChildren} from 'react';

// Styles
import './Accordion.scss';

// Interfaces
import * as INTERFACES from './AccordionInterfaces';

export const Accordion: React.FC<INTERFACES.Accordion> = (props: PropsWithChildren<INTERFACES.Accordion>) => {
  return (
    <React.Fragment>
      <div className={`accordion ${props.className ? props.className : ''}`}>
        <div
          className={`accordion__header${props.hideBorder ? ` accordion__header__borderless${props.open ? ' accordion__header__borderless--open' : ''}` : ''}`}
          onClick={() => {
            props.onHeaderClick();
          }}
        >
          {props.header}

          <i className={`accordion__icon ${props.open ? 'accordion__icon--open' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-chevron-down" viewBox="0 0 16 16">
              <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </i>
        </div>

        <div className={`accordion__body accordion__body${props.open ? '--open' : '--closed'}${props.hideBorder ? ' accordion__body--borderless' : ''}`}>
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};
