// React
import React from 'react';

// Styles
import './Loader.scss';

// Interfaces
import * as INTERFACES from './LoaderInterfaces';

const Loader: React.FC<INTERFACES.Loader> = (props: INTERFACES.Loader) => {
  const dots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  return (
    <React.Fragment>
      <div className={`loader loader--module`}>
        <div className={'section'}>
          <div className={'loader__spinner'}>
            {
              dots.map((dot, index) => (
                <span key={index} className={`dot__${index}`}/>
              ))
            }
          </div>
          <p className={'loader__title'}>{props.text ?? 'Hang in there'}</p>
        </div>
        <div className={'backdrop'}/>
      </div>
    </React.Fragment>
  );
};

export default Loader;