// Created By: Chris
// Created On: 2021/11/11

// React
import React, {useEffect, useState} from 'react';

// Middleware
import {InputTypes} from '../../../middleware/enums/Enums';
import StyleHelper from '../../../middleware/styleHelper/StyleHelper';

// Styles
import './Radio.scss';

// Interfaces
import {RadioProperties} from './RadioInterfaces';

const Radio: React.FC<RadioProperties> = (props: RadioProperties): JSX.Element => {
  // State
  const [inputStyles, setInputStyles] = useState({
    block: 'radio',
    elements: {},
    modifiers: {
      checked: props?.checked ? 'checked' : '',
      disabled: props?.disabled ? 'disabled' : '',
    },
  });

  // Effect
  useEffect(() =>{
    setInputStyles({
      ...inputStyles,
      modifiers: {
        ...inputStyles.modifiers,
        checked: props?.checked ? 'checked' : '',
        disabled: props?.disabled ? 'disabled' : '',
      },
    });
  }, [props?.checked, props?.disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div
        className={`${props.className} ${StyleHelper(inputStyles)}`}
        onClick={!props?.disabled ? (event) => {
          props?.onClick(event);
        } : undefined}
      >
        {/*INPUT*/}
        <input
          aria-label={props?.ariaLabel}
          aria-disabled={props?.disabled ?? false}
          form={props?.form}
          name={props?.name}
          checked={props?.checked}
          type={InputTypes.RADIO}
          onChange={() => null}
        />

        {/*DOT*/}
        <span className="radio-dot"/>
      </div>
    </React.Fragment>
  );
};

export default Radio;