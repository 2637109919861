// React
import React from 'react';

// Routes
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// Layouts
import Main from '../components/layout/main/Main';

// Components
import Login from '../modules/advisor/login/Login';
import Invite from '../modules/advisor/invite/Invite';
import Landing from '../modules/client/landing/Landing';
import Options from '../modules/client/options/Options';
import Test from '../modules/test/Test';
import Rapport from '../modules/client/rapport/Rapport';

export const Router: React.FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        {/*ADVISOR - LOGIN*/}
        <Route
          path="/"
          element={
            <React.Fragment>
              <Main footer={'advisor'} header={'login'}>
                <Login/>
              </Main>
            </React.Fragment>
          }
        />

        {/*ADVISOR - INVITE*/}
        <Route
          path="invite"
          element={
            <React.Fragment>
              <Main header={'advisor'} protected={true}>
                <Invite/>
              </Main>
            </React.Fragment>
          }
        />

        {/*CLIENT - LANDING*/}
        <Route
          path="landing/*"
          element={
            <React.Fragment>
              <Main header={'client_start'} path={'loading'}>
                <Landing/>
              </Main>
            </React.Fragment>
          }
        />

        {/*CLIENT - OPTIONS*/}
        <Route
          path="options"
          element={
            <React.Fragment>
              <Main header={'client'} protected={true}>
                <Options/>
              </Main>
            </React.Fragment>
          }
        />

        {/*CLIENT - ACCOUNTS*/}
        <Route
          path="bankaccounts/*"
          element={
            <React.Fragment>
              <Main header={'client'} protected={true}>
                <Options showAccounts={true}/>
              </Main>
            </React.Fragment>
          }
        />

        {/*CLIENT - RAPPORT*/}
        <Route
          path="rapport/*"
          element={
            <React.Fragment>
              <Rapport/>
            </React.Fragment>
          }
        />

        {/*TEST*/}
        <Route
          path="test"
          element={
            <React.Fragment>
              <Test/>
            </React.Fragment>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};