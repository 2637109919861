import React, {useState} from 'react';

// Interfaces
import {LoginDetails} from './LoginInterfaces';
import {Alert} from '../../../components/feedback/snackbar/SnackbarInterfaces';

// Styles
import './Login.scss';

// Components
import Input from '../../../components/controls/input/Input';
import Button from '../../../components/controls/button/Button';
import Loader from '../../../components/feedback/loader/Loader';
import Snackbar from '../../../components/feedback/snackbar/Snackbar';

// Middleware
import {ServiceHelper} from '../../../middleware/serviceHelper/ServiceHelper';
import {APITypes, InputTypes} from '../../../middleware/enums/Enums';

// Routes
import {useNavigate} from 'react-router-dom';

const Login: React.FC = (): JSX.Element => {
  // States
  const [loginDetails, setLoginDetails] = useState<LoginDetails>({username: '', password: ''});
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert>();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [requiredFields] = useState<Array<string>>(['username', 'password']);

  // Hooks
  const navigate = useNavigate();

  // Handlers
  const handleLogin = (): void => {
    if (!validateInputs()) {
      setAlert(
        {
          severity: 'warning',
          title: 'Gelieve alle verplichte velden in te vullen.',
        },
      );
      return;
    }

    if (!validateEmail(loginDetails.username)) {
      setAlert(
        {
          severity: 'warning',
          title: 'Vul een geldig e-mailadres in',
        },
      );
      return;
    }

    setLoading(true);
    ServiceHelper
      .post({
        api: APITypes.PUBLIC_IAM,
        url: '/authentication/login',
        body: loginDetails as unknown as Record<string, unknown>,
      })
      .then(result => {
        if (!result) {
          setAlert(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );
        }
        switch (result.status) {
          case 200:
            localStorage.setItem('jwt_token', result?.data?.Token ?? '');
            navigate('/invite', {state: {userId: result?.data?.UserID.toString() ?? ''}});
            break;
          default:
        }
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          setAlert(
            {
              severity: 'error',
              title: 'Oeps! Er is iets fout gegaan.',
            },
          );

          return;
        }

        setAlert(
          {
            severity: 'error',
            title: error?.response?.data ?? 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateInputs = (): boolean => {
    if (requiredFields.length === 0) return true;

    const emptyFields = [];
    let tempLoginDetails = loginDetails as Record<string, any>;

    for (let field of requiredFields) {
      if (tempLoginDetails[field] === '') {
        emptyFields.push(field);
      }
    }

    setErrors(emptyFields);
    return emptyFields.length === 0;
  };

  const validateEmail = (email: string): boolean => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  return (
    <React.Fragment>
      <div id={'login'}>
        {/*HEADER*/}
        <h2 className={'header'}>Inloggen voor adviseurs</h2>

        {/*SUB HEADER*/}
        <h4 className={'subheader'}>Vul hier je e-mailadres en wachtwoord in. Deze heb je per e-mail hebt ontvangen.</h4>

        {/*LOGIN BOX*/}
        <form name={'login'}>
          {/*USERNAME*/}
          <div className={'field-wrapper'}>
            {/*LABEL*/}
            <p className={'label'}>Gebruikersnaam</p>

            {/*INPUT*/}
            <Input
              ariaLabel={'login-username'}
              form={'login'}
              name={'username'}
              error={errors.includes('username')}
              placeholder={'Vul hier je e-mailadres in'}
              tabIndex={1}
              value={loginDetails?.username ?? ''}
              onChange={(event) => setLoginDetails({...loginDetails, username: event.target.value})}
            />
          </div>

          {/*PASSWORD*/}
          <div className={'field-wrapper'}>
            {/*LABEL*/}
            <p className={'label'}>Wachtwoord</p>

            {/*INPUT*/}
            <Input
              ariaLabel={'login-password'}
              form={'login'}
              name={'password'}
              error={errors.includes('password')}
              type={InputTypes.PASSWORD}
              placeholder={'Vul hier je wachtwoord in'}
              tabIndex={2}
              value={loginDetails?.password ?? ''}
              onChange={(event) => setLoginDetails({...loginDetails, password: event.target.value})}
            />

          </div>

          {/*BUTTON - LOGIN*/}
          <div className={'button-wrapper'}>
            <Button
              ariaLabel={'login-button-login'}
              form={'login'}
              name={'button-login'}
              variant={'primary'}
              disabled={loginDetails.username === '' || loginDetails.password === ''}
              onClick={() => handleLogin()}
              tabIndex={3}
              onKeyUp={(event) => event.key === 'Enter' ? handleLogin() : null}
            >
              Login
            </Button>
          </div>
        </form>
      </div>

      {/*LOADER*/}
      {loading &&
      <Loader
        text={'Een momentje...'}
      />
      }

      {/*SNACKBAR*/}
      {alert &&
      <Snackbar
        alert={alert}
        removeAlert={() => setAlert(undefined)}
      />
      }
    </React.Fragment>
  );
};

export default Login;