// React
import React, {useState, useRef, useEffect} from 'react';

// Components
import Input from '../../components/controls/input/Input';
import Button from '../../components/controls/button/Button';
import Checkbox from '../../components/controls/checkbox/Checkbox';
import Radio from '../../components/controls/radio/Radio';
import Progress from '../../components/feedback/progress/Progress';
import Stepper from '../../components/container/stepper/Stepper';

// Hooks

// Styles
import './Test.scss';
import {StepProps} from '../../components/container/stepper/StepperInterces';
import {useClickAway} from '../../middleware/hooks/Hooks';

let interval: any = undefined;

const Test: React.FC = (): JSX.Element => {

  // Refs
  const inputWrapperRef: React.RefObject<HTMLDivElement | undefined> = useRef();

  // State
  const [inputState, setInputState] = useState<string>('Unfilled');
  const [inputValue, setInputValue] = useState<string>('');
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const [radioValue, setRadioValue] = useState<string>('test2');
  const [progress, setProgress] = useState<number>(10);
  const [running, setRunning] = useState(false);

  // Custom hooks
  useClickAway(inputWrapperRef, () => {
    setInputState('Unfilled');
  });

  // Effects
  useEffect(() => {
    if (running) {
      if (progress === 100) {
        setProgress(0);
        interval = setInterval(() => {
          setProgress((prev) => prev + 1);
        }, 10);
      } else {
        interval = setInterval(() => {
          setProgress((prev) => prev + 1);
        }, 10);
      }
    } else {
      clearInterval(interval);
    }
  }, [running]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (progress === 100) {
      setRunning(false);
      clearInterval(interval);
    }
  }, [progress]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div id={'test'} style={{padding: '2em'}}>
        {/*TEXT*/}
        <h3>Typography</h3>
        <hr/>

        <h1>H1 - Montserrat bold - 4.8rem (48px)</h1>
        <h2>H2 - Montserrat bold - 3.2rem (32px)</h2>
        <h3>H3 - Montserrat bold - 2.6rem (26px)</h3>
        <h4>H4 - Avenir Medium - 2.4rem (24px)</h4>
        <p>Body - Avenir Book - 1.8rem (18px)</p>
        <p style={{fontWeight: 'bold'}}>Body - Avenir Heavy - 1.8rem (18px)</p>
        <p style={{fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: '1.6rem'}}>Button - Helvetica Bold - 1.6rem
          (16px)</p>

        <br/>
        <h3>Forms</h3>
        <hr/>

        {/*INPUT*/}
        <h3>Input</h3>
        <div style={{maxWidth: '45em'}}>
          <p style={{fontWeight: 'bold'}}>{inputState}</p>
          <div
            onMouseEnter={() => inputState !== 'Selected' && setInputState('Hover')}
            onMouseLeave={() => inputState !== 'Selected' && setInputState('Unfilled')}
            onClick={() => setInputState('Selected')}
            ref={inputWrapperRef as React.RefObject<HTMLDivElement>}
          >
            <Input
              ariaLabel={''}
              form={''}
              name={''}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
            />

          </div>
        </div>

        {/*BUTTON*/}
        <br/>
        <h3>Button</h3>

        <div style={{display: 'flex', gap: '1em', marginBottom: '2em'}}>
          <Button
            ariaLabel={''}
            form={''}
            name={''}
            value={''}
            variant={'primary'}
            onClick={() => alert('It works! 🍺')}
          >
            Primary
          </Button>

          <Button
            ariaLabel={''}
            form={''}
            name={''}
            value={''}
            variant={'secondary'}
            onClick={() => alert('It works! 🍺')}
          >
            Secondary
          </Button>
        </div>

        <div style={{display: 'flex', gap: '1em'}}>
          <Button
            ariaLabel={''}
            disabled={true}
            form={''}
            name={''}
            value={''}
            variant={'primary'}
            onClick={() => alert('It works! 🍺')}
          >
            Primary
          </Button>

          <Button
            ariaLabel={''}
            disabled={true}
            form={''}
            name={''}
            value={''}
            variant={'secondary'}
            onClick={() => alert('It works! 🍺')}
          >
            Secondary
          </Button>
        </div>

        {/*CHECKBOX*/}
        <h3>CHECKBOX - {checkboxValue.toString()}</h3>
        <div className={'section'}>

          <label className={'checkbox__label'}>Checkbox</label>
          <Checkbox
            ariaLabel={'Checkbox'}
            checked={checkboxValue}
            onClick={() => setCheckboxValue(!checkboxValue)}
          />

          <label className={'checkbox__label mt-1'}>Checkbox disabled</label>
          <Checkbox
            disabled={true}
            ariaLabel={'Checkbox'}
            checked={checkboxValue}
            onClick={() => setCheckboxValue(!checkboxValue)}
          />
        </div>

        {/*RADIO BUTTON*/}
        <h3>RADIO - {radioValue}</h3>
        <div className={'section'}>

          <label className={'radio__label'}>test1</label>
          <Radio className={'mb-1'} ariaLabel={'radio'} checked={radioValue === 'test1'} name={'radio'}
                 onClick={() => setRadioValue('test1')}/>

          <label className={'radio__label'}>test2</label>
          <Radio className={'mb-1'} ariaLabel={'radio'} checked={radioValue === 'test2'} name={'radio'}
                 onClick={() => setRadioValue('test2')}/>

          <label className={'radio__label'}>test3</label>
          <Radio className={'mb-1'} ariaLabel={'radio'} checked={radioValue === 'test3'} name={'radio'}
                 onClick={() => setRadioValue('test3')}/>

          <label className={'radio__label'}>Disabled</label>
          <Radio disabled={true} className={'mb-1'} ariaLabel={'Disabled'} checked={radioValue === 'Disabled'}
                 name={'radio'}
                 onClick={() => setRadioValue('Disabled')}/>

        </div>

        {/*STEPPER*/}
        <div className={'stepper-wrapper'}>
          <Stepper
            steps={[
              {
                title: 'Step 1',
                // Render whatever you want here, we will improve this later
                element: stepProps => <Step {...stepProps} />,
              },
              {
                title: 'Step 2',
                element: stepProps => <Step {...stepProps} />,
              },
              {
                title: 'Step 3',
                element: stepProps => <Step {...stepProps} />,
              },
              {
                title: 'Step 4',
                element: stepProps => <Step {...stepProps} />,
              },
              {
                title: 'Step 53',
                element: stepProps => <Step {...stepProps} />,
              },
              {
                title: 'Step 6',
                element: stepProps => <Step {...stepProps} />,
              },
            ]}
          />
        </div>

        {/*PROGRESS*/}
        <h3>PROGRESS</h3>
        <div className={'section'}>
          <Progress
            className={'mb-1'}
            width={progress}
            ariaLabel={'Progress'}
            name={'Progress'}
          />
          <div style={{display: 'flex', gap: '1em'}}>
            <Button
              ariaLabel={''}
              form={''}
              name={''}
              value={''}
              variant={'primary'}
              onClick={() => {
                setRunning(false);
                setProgress(0);
              }}
            >
              Clear
            </Button>
            <Button
              ariaLabel={''}
              form={''}
              name={''}
              value={''}
              variant={'secondary'}
              onClick={() => setRunning(!running)}
            >
              {running ? 'Stop' : progress === 100 ? 'Restart' : 'Start'}
            </Button>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

const Step: React.FC<StepProps> = ({
                                     goNextStep,
                                     goPreviousStep,
                                     isFirst,
                                     isLast,
                                     step,
                                   }) => {
  return (
    <div className="step">
      <div className="step-body">Step {step}</div>
      <div className="step-actions">
        <button
          className="step-button"
          disabled={isFirst}
          onClick={goPreviousStep}
        >
          GO PREVIOUS Wololo 2
        </button>
        <button className="step-button" disabled={isLast} onClick={goNextStep}>
          GO NEXT Wololo
        </button>
      </div>
    </div>
  );
};

export default Test;