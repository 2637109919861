// React
import React, { useEffect, useState } from 'react';

// Styles
import './Details.scss';

// Interfaces
import {
  Account,
  DetailsProperties,
  NibudValues,
  PolicyContent,
  PolicyDetails,
  TinkValues,
  Person,
  NibudExpense,
  AccordionState,
} from './DetailsInterfaces';
import { Alert } from '../../../../../components/feedback/snackbar/SnackbarInterfaces';

// Images
import { ReactComponent as Check } from '../../../../../assets/icons/check.svg';
import { ReactComponent as Lightning } from '../../../../../assets/icons/lightning.svg';
import { ReactComponent as Clock } from '../../../../../assets/icons/clock.svg';
import { ReactComponent as Baloons } from '../../../../../assets/icons/balloons.svg';
import { ReactComponent as Credit } from '../../../../../assets/icons/credit.svg';
import { ReactComponent as File } from '../../../../../assets/icons/file.svg';
import { ReactComponent as Touch } from '../../../../../assets/icons/touch.svg';
import { ReactComponent as Speaker } from '../../../../../assets/icons/speaker.svg';
import { ReactComponent as User } from '../../../../../assets/icons/user.svg';
import { ReactComponent as CheckCircle } from '../../../../../assets/icons/check-circle.svg';
import Phone from '../../../../../assets/images/phone.png';
import ABNA from '../../../../../assets/images/logos/ABNA.jpg';
import BUNQ from '../../../../../assets/images/logos/BUNQ.jpg';
import INGB from '../../../../../assets/images/logos/INGB.jpg';
import KNAB from '../../../../../assets/images/logos/KNAB.jpg';
import NTSB from '../../../../../assets/images/logos/NTSB.jpg';
import RABO from '../../../../../assets/images/logos/RABO.jpg';
import RBRB from '../../../../../assets/images/logos/RBRB.jpg';
import REVO from '../../../../../assets/images/logos/REVO.jpg';
import SNSB from '../../../../../assets/images/logos/SNSB.png';
import TRIO from '../../../../../assets/images/logos/TRIO.jpg';
import DEFAULT from '../../../../../assets/images/logos/default.png';

// Components
import Checkbox from '../../../../../components/controls/checkbox/Checkbox';
import Radio from '../../../../../components/controls/radio/Radio';
import Button from '../../../../../components/controls/button/Button';
import Loader from '../../../../../components/feedback/loader/Loader';
import Snackbar from '../../../../../components/feedback/snackbar/Snackbar';
import { Accordion } from '../../../../../components/container/accordion/Accordion';
import Input from '../../../../../components/controls/input/Input';
import { Select } from '../../../../../components/controls/select/Select';

// Routes
import { useNavigate } from 'react-router-dom';

// Services
import { ServiceHelper } from '../../../../../middleware/serviceHelper/ServiceHelper';

// Enums
import { APITypes, GlobalState, OwnerTypes, Pages } from '../../../../../middleware/enums/Enums';

// Utility
import moment from 'moment';
import { SingularToPlural } from '../../../../../helpers/singularToPlural';
import { useGlobalState } from '../../../../../middleware/hooks/Hooks';

const Details: React.FC<DetailsProperties> = (props: DetailsProperties): JSX.Element => {
  // Global State
  const [hasPartner, setHasPartner] = useGlobalState(GlobalState.HAS_PARTNER);
  const [activePage, setActivePage] = useGlobalState(GlobalState.ACTIVE_PAGE);

  // States
  const [selectedFlow, setSelectedFlow] = useState<string>(Pages.Estimate);
  const [policyDetails, setPolicyDetails] = useState<PolicyDetails>();
  const [policyContent, setPolicyContent] = useState<PolicyContent>();
  const [selectedAccounts, setSelectedAccounts] = useState<Array<string>>([]);
  const [isMoreInfoFirstBlockShown, setIsMoreInfoFirstBlockShown] = useState<boolean>(false);
  const [isMoreInfoSecondBlockShown, setIsMoreInfoSecondBlockShown] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Array<Account>>();
  const [consent, setConsent] = useState<boolean>(false);
  const [tinkUrl, setTinkUrl] = useState<string>('');
  const [tinkValues, setTinkValues] = useState<TinkValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<Alert>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [nibudValues, setNibidValues] = useState<NibudValues>({
    Expenses: [{
      Owners: [
        OwnerTypes.Client,
      ],
      Type: 'Woning',
      Amount: '',
      ApplicationID: localStorage?.getItem('applicationId') ?? '',
    }],
    Incomes: [
      {
        Type: 'Employed',
        IncomeObjectType: 'Net',
        Owner: OwnerTypes.Client,
        MonthlyIncome: {
          Value: '',
        },
        YearlyIncomes: [
          {
            Value: 0,
            Year: new Date().getFullYear(),
          },
        ],
        ApplicationID: localStorage?.getItem('applicationId') ?? '',
      },
    ],
    Person: [],
    RealEstates: [
      {
        Type: 'Apartment',
        Owners: [
          OwnerTypes.Client,
        ],
        Usage: 'MainResidence',
        WOZWaarde: 199999,
        YearOfConstruction: 1965,
        ApplicationID: localStorage?.getItem('applicationId') ?? '',
      },
    ],
  });
  const [visibleQuestions, setVisibleQuestions] = useState<Array<string>>([]);
  const [childAmount, setChildAmount] = useState<Array<unknown>>(['0']);
  const [nibudExpenses, setNibudExpenses] = useState<Array<NibudExpense>>([]);
  const [accordionOpen, setAccordionOpen] = useState<AccordionState>({
    personal: true,
    incomes: false,
    expenses: false,
  });
  const [peopleSaved, setPeopleSaved] = useState<boolean>(false);
  const [isPersonAgeValid, setPersonAgeValid] = useState(true);
  const [isIncomeValid, setIncomeValid] = useState(true);
  const [isRentValid, setRentValid] = useState(true);
  const [isMortgageValid, setMortgageValid] = useState(true);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    if (!activePage) return;
    if (activePage !== Pages.Real_Picture) return;

    let params = new URLSearchParams(window.location.search);

    getAccounts(true);
    getLatestActivePolicy();

    setTinkValues({
      code: params?.get('code') ?? '',
      credentialsId: params?.get('credentialsId') ?? '',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tinkUrl) return;

    window.location.href = tinkUrl;
    setTinkUrl('');
  }, [tinkUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tinkValues || tinkValues.code === '' || tinkValues.credentialsId === '') return;
    setLoading(true);

    saveAccounts();
  }, [tinkValues]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const getLatestActivePolicy = (): void => {
    setLoading(true);

    ServiceHelper
      .get({
        api: APITypes.CF,
        url: '/policy/active',
      })
      .then((result) => {
        const filteredResult = result.data.filter((object: any) => {
          return object.PolicyType === 'Privacy';
        });

        const item = filteredResult.reduce((prev: { Version: string | number; }, current: { Version: string | number; }) => (+prev.Version > +current.Version) ? prev : current);

        getConsentData(item.Reference, item.Version);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const getConsentData = (policyReference: string, policyVersion: string): void => {
    ServiceHelper
      .get({
        api: APITypes.CF,
        url: `/policyContent/${policyReference}/${policyVersion}`,
      })
      .then(policyResult => {
        setPolicyContent(policyResult.data);
        setPolicyDetails({
          PolicyReference: policyReference,
          PolicyVersion: policyVersion,
        });
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendConsentData = (type?: string): void => {
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .post({
        api: APITypes.CF,
        url: '/gdpr/authorize',
        body: {
          ...policyDetails,
          ApplicationID: applicationId,
        },
      })
      .then(() => {
        if (type === Pages.Nibud) {
          setActivePage(Pages.Nibud);
          return;
        }

        generateTinkUrl();
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateTinkUrl = (): void => {
    ServiceHelper
      .post({
        //body: {Provider: 'Tink'},
        body: { Provider: 'Tink' },
        api: APITypes.IAM,
        url: '/OAuth/generateLoginUrl',
      })
      .then(result => {
        setTinkUrl(result.data);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const saveAccounts = (): void => {
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .post({
        api: APITypes.PSD2,
        url: `/applications/${applicationId}/bankAccounts`,
        body: {
          AuthCode: tinkValues?.code ?? '',
        },
      })
      .then(() => {
        setLoading(true);
        getAccounts();
        setActivePage(Pages.Accounts);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });
  };

  const showFirstBlockPrivacyPolicy = () => {
    setIsMoreInfoFirstBlockShown(true);
  };

  const getAccounts = (init?: boolean): number | void => {
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .get({
        api: APITypes.PSD2,
        url: `/applications/${applicationId}/bankAccounts`,
      })
      .then(result => {
        setAccounts(result?.data ?? []);

        if (result.data.length > 0) {
          setActivePage(Pages.Accounts);
        }
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        if (init) return;

        setLoading(false);
      });
  };

  const handleSavePeople = async (): Promise<void> => {
    setLoading(true);
    let applicationId = localStorage.getItem('applicationId');

    try {
      let tempBody = [...nibudValues.Person].filter(function (person) {
        return person !== undefined;
      });
      const newPerson = await ServiceHelper
        .post({
          api: APITypes.CF,
          url: '/persons',
          body: nibudValues ? tempBody as unknown as Record<string, unknown> : {},
        });

      const newPersonIds = newPerson?.data?.map(({ ID }: any) => ID) ?? [];

      const persons = await ServiceHelper
        .get({
          api: APITypes.CF,
          url: `/persons/${applicationId}`,
        });

      await Promise.all(
        persons?.data
          .filter(({ ID }: any) => ID && !newPersonIds.includes(ID))
          .map(({ ID }: any) =>
            ServiceHelper
              .delete({
                api: APITypes.CF,
                url: '/persons',
                id: ID
              })
          )
      );

      setAccordionOpen({ incomes: true, personal: false, expenses: false });
    } catch {
      setSnackbarMessage({
        severity: 'error',
        title: 'Oeps! Er is iets fout gegaan.',
      });
    } finally {
      setLoading(false);
      setPeopleSaved(true);
    }
  };

  const handleSaveIncomesExpenses = (): void => {
    saveExpenses();
  };

  const saveIncomes = async (): Promise<void> => {
    let applicationId = localStorage.getItem('applicationId');

    try {
      const newPerson = await ServiceHelper
        .post({
          api: APITypes.CF,
          url: '/incomes',
          body: nibudValues ? nibudValues.Incomes as unknown as Record<string, unknown> : {},
        });

      const newPersonId = newPerson?.data?.[0]?.ID;
      const incomes = await ServiceHelper
        .get({
          api: APITypes.CF,
          url: `/incomes/${applicationId}`,
        });
      await Promise.all(incomes?.data
        .filter(({ ID }: any) => ID != newPersonId)
        .map(({ ID }: any) =>
          ServiceHelper
            .delete({
              api: APITypes.CF,
              url: '/incomes',
              id: ID,
            }),
        ));


    } catch {
      setSnackbarMessage(
        {
          severity: 'error',
          title: 'Oeps! Er is iets fout gegaan.',
        },
      );
    }
  };

  const saveRealEstates = async (): Promise<void> => {
    let applicationId = localStorage.getItem('applicationId');

    try {
      const newPerson = await ServiceHelper
        .post({
          api: APITypes.CF,
          url: '/realestates',
          body: nibudValues ? nibudValues.RealEstates as unknown as Record<string, unknown> : {},
        });

      const newPersonId = newPerson?.data?.[0]?.ID;
      const realEstates = await ServiceHelper
        .get({
          api: APITypes.CF,
          url: `/realestates/${applicationId}`,
        });

      await Promise.all(realEstates?.data
        .filter(({ ID }: any) => ID != newPersonId)
        .map(({ ID }: any) =>
          ServiceHelper
            .delete({
              api: APITypes.CF,
              url: '/realestates',
              id: ID,
            }),
        ));
    } catch {
      setSnackbarMessage(
        {
          severity: 'error',
          title: 'Oeps! Er is iets fout gegaan.',
        },
      );
    } finally {
      setTimeout(() => {
        getNibudExpenses();
      }, 1000);
    }
  };

  const saveExpenses = async (): Promise<void> => {
    setLoading(true);
    let applicationId = localStorage.getItem('applicationId');

    try {
      await ServiceHelper
        .post({
          api: APITypes.CF,
          url: '/expenses',
          body: nibudValues ? nibudValues.Expenses as unknown as Record<string, unknown> : {},
        });

      await ServiceHelper
        .get({
          api: APITypes.CF,
          url: `/expenses/${applicationId}`,
        });


      if (visibleQuestions.includes('8')) {
        saveRealEstates();
      }
    } catch {
      setSnackbarMessage(
        {
          severity: 'error',
          title: 'Oeps! Er is iets fout gegaan.',
        },
      );
    } finally {
      if (!visibleQuestions.includes('8')) {
        setTimeout(() => {
          getNibudExpenses();
        }, 1000);
      }
      setLoading(false);
    }
  };

  const getNibudExpenses = (): void => {
    let applicationId = localStorage.getItem('applicationId');
    ServiceHelper
      .get({
        api: APITypes.CF,
        url: `/nibud/${applicationId}/current`,
      })
      .then(result => {
        let tempNibudExpenses = [];
        for (let expense of result?.data) {

          tempNibudExpenses.push({
            ApplicationID: expense?.ApplicationID ?? '',
            Type: expense?.Type ?? '',
            Amount: expense?.Amount ?? '',
            NewAmount: expense?.NewAmount ?? '',
            Owners: [OwnerTypes.Client] ?? [],
            Frequency: expense?.Frequency ?? '',
            Period: expense?.Period ?? '',
            Description: handleNibudExpenseDescription(expense?.Type),
          });
        }
        setNibudExpenses(tempNibudExpenses);
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      })
      .finally(() => {
        setAccordionOpen({
          incomes: false,
          personal: false,
          expenses: !accordionOpen.expenses,
        });
        setLoading(false);
      });
  };

  const updateNibudExpenses = (): void => {
    ServiceHelper
      .post({
        api: APITypes.CF,
        url: '/expenses',
        body: nibudExpenses as unknown as Record<string, unknown>
      })
      .then(() => {
        props.onNibudComplete();
        props.goNextStep();
      })
      .catch(() => {
        setSnackbarMessage(
          {
            severity: 'error',
            title: 'Oeps! Er is iets fout gegaan.',
          },
        );
      });

    saveIncomes();
  };

  const handleNibudExpenseDescription = (type: string): string => {
    if (!type) return '';

    let result = '';

    switch (type) {
      case 'UtilityCosts':
        result = 'Woonlasten';
        break;
      case 'Vervoer':
        result = 'Reiskosten';
        break;
      case 'OverigeVasteLasten':
        result = 'Persoonlijk onvermijdbare lasten';
        break;
      case 'OnregelmatigeUitgaven':
        result = 'Overige onregelmatige lasten';
        break;
      case 'Vakantie/vrije tijd':
        result = 'Vakantie en vrije tijd';
        break;
      case 'BoodschappenHuishoudgeld':
        result = 'Voeding';
        break;
      case 'TelecomCredit':
        result = 'Telefoon, televisie en internet';
        break;
      case 'Insurances':
        result = 'Verzekeringen';
        break;
      case 'HouseholdExpense':
        result = 'Gezondheid, verzorging en huishoudelijke uitgaven';
        break;
      case 'Subscriptions':
        result = 'Abonnementen en contributies';
        break;
      default:
    }

    return result;
  };


  const handleHasPartnerChange = (value: boolean) => {
    let tempFieldVisibility = [...visibleQuestions];

    if (!value) {
      let filtered = tempFieldVisibility.filter(field => field !== '2b');
      const noPartnerPerson = nibudValues.Person.filter((person) => {
        return person.Type !== OwnerTypes.Partner;
      })

      setVisibleQuestions(filtered);
      setNibidValues({
        ...nibudValues,
        Person: noPartnerPerson,
      });

      setTimeout(() => {
        setHasPartner(value);
      }, 100);

      return;
    }

    tempFieldVisibility.push('2b');
    tempFieldVisibility.push('6b');
    setVisibleQuestions(tempFieldVisibility);

    setTimeout(() => {
      setHasPartner(value);
    }, 100);
  }

  const handlePersonValue = (index: number): string => {
    if (!nibudValues
      || !nibudValues.Person
      || !nibudValues.Person[index]
      || !nibudValues.Person[index].DateOfBirth
    ) return '';

    let currentYear = moment().year();
    let birthYear = moment(nibudValues.Person[index].DateOfBirth).year();

    let age = currentYear - birthYear;

    return age !== 0 ? age.toString() : '';
  };

  const handlePersonValueChange = (index: number, age: number): void => {
    if (isNaN(Number(age))) return;
    if (age < 0) return;
    if (index === 0) {
      setPersonAgeValid(age >= 18 && age <= 67);
    }

    let tempAge = age;

    let applicationId = localStorage.getItem('applicationId') ?? '';
    let startOfYearDate = moment().startOf('year').format('YYYY-MM-DD');
    let dateOfBirth = moment(startOfYearDate).subtract(tempAge, 'years').format('YYYY-MM-DD');
    let tempPeople: Array<Person> = [...nibudValues?.Person ?? []];
    tempPeople[index] = {
      Type: index === 0 ? OwnerTypes.Client : index === 1 ? OwnerTypes.Partner : OwnerTypes.Child,
      DateOfBirth: dateOfBirth,
      ApplicationID: applicationId,
    };

    setNibidValues({
      ...nibudValues,
      Person: tempPeople,
    });
  };

  const handleAccountIcon = (iban: string): string => {
    let image;
    let banks = [
      {
        code: 'ABNA',
        image: ABNA,
      },
      {
        code: 'INGB',
        image: INGB,
      },
      {
        code: 'KNAB',
        image: KNAB,
      },
      {
        code: 'NTSB',
        image: NTSB,
      },
      {
        code: 'RABO',
        image: RABO,
      },
      {
        code: 'RBRB',
        image: RBRB,
      },
      {
        code: 'REVO',
        image: REVO,
      },
      {
        code: 'SNSB',
        image: SNSB,
      },
      {
        code: 'TRIO',
        image: TRIO,
      },
      {
        code: 'BUNQ',
        image: BUNQ,
      },
    ];

    for (let bank of banks) {
      if (iban.includes(bank.code)) {
        image = bank.image;
      }
    }

    return `url(${image ?? DEFAULT})`;
  };

  const showPluralWordIfApplicable = (string: string): string => {
    return hasPartner ? SingularToPlural.convert(string) : string;
  }

  return (
    <React.Fragment>
      <div className={`details${loading ? ' details__disable-overflow' : ''}`}>
        {activePage === Pages.Real_Picture &&
          <div className={'echte'}>
            {/*HEADER*/}
            <h3 className={'header'}>Verzamel {showPluralWordIfApplicable("je")} gegevens</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*HEADER*/}
              <h4 className={'header'}>Je banktransacties ophalen met Tink</h4>

              {/*SUB HEADER*/}
              <p className={'subheader'}>
                <i className={'icon'}>
                  <Lightning />
                </i>
                Duurt 2 minuten
              </p>

              {/*SECTION 1*/}
              <div className={'section1'}>
                {/*SELECTEER JE BANK*/}
                <div className={'step'}>
                  {/*IMAGE*/}
                  <div className={'image-wrapper'}>
                    <Credit />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>1. Selecteer je bank</p>
                </div>

                {/*LINE*/}
                <div className={'line'} />

                {/*LOGIN IN JE BANK OMGEVING*/}
                <div className={'step'}>
                  {/*IMAGE*/}
                  <div className={'image-wrapper'}>
                    <User />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>2. Login in je bank omgeving</p>
                </div>

                {/*LINE*/}
                <div className={'line'} />

                {/*SELECTEER JE REKENINGEN*/}
                <div className={'step'}>
                  {/*IMAGE*/}
                  <div className={'image-wrapper'}>
                    <Touch />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>3. Selecteer je rekeningen</p>
                </div>

                {/*LINE*/}
                <div className={'line'} />

                {/*HAAL JE GEGEVENS OP*/}
                <div className={'step'}>
                  {/*IMAGE*/}
                  <div className={'image-wrapper'}>
                    <File />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>4. Haal je gegevens op</p>
                </div>

                {/*LINE*/}
                <div className={'line'} />

                {/*HET ECHTE PLAATJE STAAT VOOR JE KLAAR*/}
                <div className={'step'}>
                  {/*IMAGE*/}
                  <div className={'image-wrapper'}>
                    <Baloons />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>5. Je betaalprofiel staat klaar</p>
                </div>
              </div>

              {/*SECTION 2*/}
              {policyContent &&
                <div className={'section2'}>
                  {/*CHECKBOX*/}
                  <div className={'checkbox-container'}>
                    <Checkbox
                      ariaLabel={'permssion-checkbox'}
                      checked={consent}
                      onClick={() => setConsent(!consent)}
                    />
                  </div>

                  {/*HEADER*/}
                  <h4 className={'header'}>{policyContent?.Title ?? ''}</h4>

                  {/*DESCRIPTION*/}
                  <div className={'description'}>
                    <p className={'description'}>{policyContent?.Content ?? ''}</p>

                    {!isMoreInfoFirstBlockShown && <div className={'description-button'}>
                      <a
                        className={'info-link link'}
                        href=""
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setIsMoreInfoFirstBlockShown(true);
                        }}
                      >
                        Meer informatie
                      </a>
                    </div>}

                    {isMoreInfoFirstBlockShown && <div>
                      <p className={'secondary-description'}>
                        Met jouw toestemming sturen we jouw persoonsgegevens door naar Tink. Tink gebruikt deze gegevens
                        om de bij- en afschrijvingen op te halen bij de banken waar jij een betaalrekening hebt. Tink
                        heeft een vergunning om dat te doen. Nadat Tink de bij- en afschrijvingen van jouw rekening(en)
                        heeft ontvangen, geeft Tink deze gegevens aan ons. Daarmee berekenen we jouw besteedbaar inkomen
                        en bepalen we waar je je geld aan uitgeeft. Daarna vernietigen wij de gegevens. Na het ophalen
                        en doorsturen naar Beyond vernietigt Tink de gegevens meteen. Tink gebruikt je gegevens nergens
                        anders voor.
                      </p>
                      <p className={'secondary-description'}>
                        Wil je je toestemming intrekken? Dan kun je dat op ieder moment doen door de pagina af te
                        sluiten.
                        Op de verwerking van je persoonsgegevens door Tink is de <a
                          href="https://link.tink.com/privacy-policy/nl" target="_blank" className={'link'}>privacyverklaring
                          van Tink</a> van toepassing.
                        Heb je vragen over onze verwerking van je persoonsgegevens? Mail dan naar <a
                          href="mailto:info@beyond.nl" target="_blank" className={'link'}>info@beyond.nl</a>.
                      </p>
                    </div>}
                  </div>
                </div>
              }

              {/*SECTION 3*/}
              <div className={'section3'}>
                {/*TEXT*/}
                <p className={'description'}>
                  Liever geen banktransacties delen?
                  <span
                    className={'link'}
                    onClick={() => setActivePage(Pages.Estimate)}
                  >
                    Kies voor een schatting
                  </span>
                </p>
              </div>

              {/*ACTIONS*/}
              <div className={'actions'}>
                {/*BUTTON - BACK*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-back'}
                    form={''}
                    name={'back'}
                    variant={'secondary'}
                    onClick={() => navigate(`/landing?invId=${localStorage.getItem('invitationId')}`)}
                  >
                    Vorige
                  </Button>

                </div>

                {/*BUTTON - START*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-start'}
                    form={''}
                    name={'start'}
                    disabled={!consent}
                    variant={'primary'}
                    onClick={() => {
                      setLoading(true);
                      sendConsentData();
                    }}
                  >
                    Start met verzamelen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {activePage === Pages.Estimate &&
          <div className={'een'}>
            {/*HEADER*/}
            <h3 className={'header'}>Verzamel {showPluralWordIfApplicable("je")} gegevens</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*HEADER*/}
              <h4 className={'header'}>Je aanpak wijzigen</h4>

              {/*SECTION 1*/}
              <div className={'section1'}>
                {/*ECHTE PLAATJIE*/}
                <div
                  className={`card${selectedFlow === Pages.Real_Picture ? ' card--active' : ''}`}
                  onClick={() => setSelectedFlow(Pages.Real_Picture)}
                >
                  {/*RADIO*/}
                  <div className={'container-radio'}>
                    <Radio
                      ariaLabel={'radio-echte-plaatjie'}
                      checked={selectedFlow === Pages.Real_Picture}
                      onClick={() => setSelectedFlow(Pages.Real_Picture)}
                      name={'radio-echte-plaatjie'}
                    />
                  </div>

                  {/*CONTENT*/}
                  <div className={'card-content'}>
                    {/*HEADER*/}
                    <h4 className={'card-header'}>Jouw persoonlijke betaalprofiel</h4>

                    {/*DESCRIPTION*/}
                    <p className={'card-description'}>
                      Inzicht in een nauwkeurig betaalprofiel, precies afgestemd op jouw betaalgedrag.
                    </p>

                    {/*LINK*/}
                    <p
                      className={'card-link'}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setModalOpen(true);
                      }}
                    >
                      Waarom voor deze optie kiezen?
                    </p>
                  </div>

                  {/*DURATION*/}
                  <div className={'duration'}>
                    <p className={'subheader'}>
                      <i className={'icon'}>
                        <Lightning />
                      </i>
                      2 minuten
                    </p>
                  </div>
                </div>

                {/*EEN SCHATTING*/}
                <div
                  className={`card${selectedFlow === Pages.Estimate ? ' card--active' : ''}`}
                  onClick={() => setSelectedFlow(Pages.Estimate)}
                >
                  {/*CHECKBOX*/}
                  <div className={'container-radio'}>
                    <Radio
                      ariaLabel={'radio-een-schatting'}
                      checked={selectedFlow === Pages.Estimate}
                      onClick={() => setSelectedFlow(Pages.Estimate)}
                      name={'radio-een-schatting'}
                    />
                  </div>

                  {/*CONTENT*/}
                  <div className={'card-content'}>
                    {/*HEADER*/}
                    <h4 className={'card-header'}>Jouw geschatte betaalprofiel</h4>

                    {/*DESCRIPTION*/}
                    <p className={'card-description'}>
                      Inzicht in een geschat betaalprofiel op basis van enkele persoonlijke vragen.
                    </p>
                  </div>

                  {/*DURATION*/}
                  <div className={'duration'}>
                    <p className={'subheader'}>
                      <i className={'icon'}>
                        <Clock />
                      </i>
                      10 minuten
                    </p>
                  </div>
                </div>
              </div>

              {/*SECTION 2*/}
              {policyContent &&
                <div className={'section2'}>
                  {/*CHECKBOX*/}
                  <div className={'checkbox-container'}>
                    <Checkbox
                      ariaLabel={'permssion-checkbox'}
                      checked={consent}
                      onClick={() => setConsent(!consent)}
                    />
                  </div>

                  {/*HEADER*/}
                  <h4 className={'header'}>{policyContent?.Title ?? ''}</h4>

                  {/*DESCRIPTION*/}
                  <div className={'description'}>
                    <p className={'description'}>{policyContent?.Content ?? ''}</p>

                    {!isMoreInfoSecondBlockShown && <div className={'description-button'}>
                      <a
                        className={'info-link link'}
                        href=""
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setIsMoreInfoSecondBlockShown(true);
                        }}
                      >
                        Meer informatie
                      </a>
                    </div>}

                    {isMoreInfoSecondBlockShown && <div>
                      <p className={'secondary-description'}>
                        Met jouw toestemming sturen we jouw persoonsgegevens door naar Tink. Tink gebruikt deze gegevens
                        om de bij- en afschrijvingen op te halen bij de banken waar jij een betaalrekening hebt. Tink
                        heeft een vergunning om dat te doen. Nadat Tink de bij- en afschrijvingen van jouw rekening(en)
                        heeft ontvangen, geeft Tink deze gegevens aan ons. Daarmee berekenen we jouw besteedbaar inkomen
                        en bepalen we waar je je geld aan uitgeeft. Daarna vernietigen wij de gegevens. Na het ophalen
                        en doorsturen naar Beyond vernietigt Tink de gegevens meteen. Tink gebruikt je gegevens nergens
                        anders voor.
                      </p>
                      <p className={'secondary-description'}>
                        Wil je je toestemming intrekken? Dan kun je dat op ieder moment doen door de pagina af te
                        sluiten.
                        Op de verwerking van je persoonsgegevens door Tink is de <a
                          href="https://link.tink.com/privacy-policy/nl" target="_blank" className={'link'}>privacyverklaring
                          van Tink</a> van toepassing.
                        Heb je vragen over onze verwerking van je persoonsgegevens? Mail dan naar <a
                          href="mailto:info@beyond.nl" target="_blank" className={'link'}>info@beyond.nl</a>.
                      </p>
                    </div>}
                  </div>
                </div>
              }

              {/*ACTIONS*/}
              <div className={'actions'}>
                {/*BUTTON - BACK*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-back'}
                    form={''}
                    name={'back'}
                    variant={'secondary'}
                    onClick={() => setActivePage(Pages.Real_Picture)}
                  >
                    Vorige
                  </Button>

                </div>

                {/*BUTTON - START*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-start'}
                    form={''}
                    name={'start'}
                    disabled={!consent}
                    variant={'primary'}
                    onClick={() => {
                      setLoading(true);

                      if (selectedFlow === Pages.Real_Picture) {
                        sendConsentData();
                        return;
                      }

                      sendConsentData(Pages.Nibud);
                    }}
                  >
                    Ga door met mijn keuze
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {activePage === Pages.Accounts &&
          <div className={'accounts'}>
            {/*HEADER*/}
            <h3 className={'header'}>Verzamel {showPluralWordIfApplicable("je")} gegevens</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*HEADER*/}
              <h4 className={'header'}>Opgehaalde rekeningen</h4>

              {/*SECTION 1*/}
              <div className={'section1'}>
                {accounts && accounts.length > 0 && accounts.map((account, index) => (
                  <div
                    className={`row-account${selectedAccounts.includes(account.Id) ? ' row-account--selected' : ''}`}
                    key={`account-row-${index}`}
                  >
                    {/*ACCOUNT*/}
                    <div className={'account'}>
                      {/*ICON*/}
                      <div
                        className={'icon'}
                        style={{
                          backgroundImage: handleAccountIcon(account.Iban ?? ''),
                        }}
                      />

                      {/*ACCOUNT NAME*/}
                      <p className={'name'}>{`Rekening ${index + 1}`}</p>
                    </div>

                    {/*IBAN*/}
                    <p className={'iban'}>{account.Iban ?? ''}</p>

                    {/*LINKED*/}
                    <div className={'linked'}>
                      <p>gekoppeld</p>
                      <Check />
                    </div>
                  </div>
                ))}
              </div>

              {/*SECTION 2*/}
              <div className={'section2'}>
                {/*ACTIONS*/}
                <div className={'actions'}>
                  {/*ADD MORE LINK*/}
                  <p className={'add'} onClick={() => generateTinkUrl()}>+Rekening(en) toevoegen</p>
                </div>

                {/*DESCRIPTION*/}
                <div className={'description-container'}>
                  {/*ICON*/}
                  <div className={'icon'}>
                    <Speaker />
                  </div>

                  {/*DESCRIPTION*/}
                  <p className={'description'}>Voor een volledig overzicht is het, indien van toepassing,
                    belangrijk om ook rekeningen bij andere banken of die van je partner op te halen.
                    Houd in dat geval de inloggegevens bij de hand. </p>
                </div>
              </div>

              {/*ACTIONS*/}
              <div className={'actions'}>
                {/*BUTTON - BACK*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-back'}
                    form={''}
                    name={'back'}
                    variant={'secondary'}
                    onClick={() => {
                      getLatestActivePolicy();
                      setActivePage(Pages.Real_Picture);
                    }}
                  >
                    Vorige
                  </Button>

                </div>

                {/*BUTTON - START*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-start'}
                    form={''}
                    name={'start'}
                    variant={'primary'}
                    onClick={() => props.goNextStep()}
                  >
                    Verder met deze rekening(en)
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {activePage === Pages.Nibud &&
          <div className={'nibud'}>
            {/*HEADER*/}
            <h3 className={'header'}>Verzamel {showPluralWordIfApplicable("je")} gegevens</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*PERSONAL QUESTIONS*/}
              <div className={'container-accordion'}>
                <Accordion
                  header={<h4 className={'accordion-header'}>Gezinssituatie</h4>}
                  hideBorder={true}
                  onHeaderClick={() => setAccordionOpen({
                    personal: !accordionOpen.personal,
                    expenses: false,
                    incomes: false,
                  })}
                  open={accordionOpen.personal}
                >
                  {/*QUESTION 1*/}
                  <div className={'question'}>
                    {/*LABEL*/}
                    <p className={'label'}>Heb je een partner?</p>

                    {/*CONTROL*/}
                    <div className={'control-wrapper control-wrapper__radio'}>
                      <div className={'radio-wrapper'}>
                        <label>Ja</label>
                        <Radio
                          ariaLabel={'radio-partner-yes'}
                          name={'radio-partner-yes'}
                          onClick={() => {
                            handleHasPartnerChange(true);
                          }}
                          checked={visibleQuestions?.includes('2b')}
                        />
                      </div>

                      <div className={'radio-wrapper'}>
                        <label>Nee</label>
                        <Radio
                          ariaLabel={'radio-partner-no'}
                          name={'radio-partner-no'}
                          onClick={() => {
                            handleHasPartnerChange(false);
                          }}
                          checked={!visibleQuestions?.includes('2b')}
                        />
                      </div>
                    </div>
                  </div>

                  {/*QUESTION 2a*/}
                  <div className={'question'}>
                    {/*LABEL*/}
                    <p className={'label'}>Hoe oud ben je?</p>

                    {/*CONTROL*/}
                    <div className={'control-wrapper'}>
                      <Input
                        ariaLabel={'age'}
                        endAdornment={'jaar'}
                        form={''}
                        name={'age'}
                        value={handlePersonValue(0)}
                        onChange={(event) => handlePersonValueChange(0, event.target.value as unknown as number)}
                      />

                      {/*VALIDATION*/}
                      <div>
                        <p className={'error-massage'}>
                          {!isPersonAgeValid && 'Vul een leeftijd in tussen de 18 en 67'}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*QUESTION 2b*/}
                  {visibleQuestions.includes('2b') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Hoe oud is je partner?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Input
                          ariaLabel={'age'}
                          endAdornment={'jaar'}
                          form={''}
                          name={'age'}
                          value={handlePersonValue(1)}
                          onChange={(event) => handlePersonValueChange(1, event.target.value as unknown as number)}
                        />
                      </div>
                    </div>
                  }

                  {/*QUESTION 3*/}
                  <div className={'question'}>
                    {/*LABEL*/}
                    <p className={'label'}>Heb {showPluralWordIfApplicable("je")} thuiswonende kinderen?</p>

                    {/*CONTROL*/}
                    <div className={'control-wrapper control-wrapper__radio'}>
                      <div className={'radio-wrapper'}>
                        <label>Ja</label>
                        <Radio
                          ariaLabel={'radio-children-yes'}
                          name={'radio-children-yes'}
                          onClick={() => {
                            let tempFieldVisibility = [...visibleQuestions];

                            tempFieldVisibility.push('4');
                            setVisibleQuestions(tempFieldVisibility);
                          }}
                          checked={visibleQuestions?.includes('4')}
                        />
                      </div>

                      <div className={'radio-wrapper'}>
                        <label>Nee</label>
                        <Radio
                          ariaLabel={'radio-children-no'}
                          name={'radio-children-no'}
                          onClick={() => {
                            let tempFieldVisibility = [...visibleQuestions];
                            let filtered = tempFieldVisibility.filter(field => field !== '4');

                            setVisibleQuestions(filtered);
                          }}
                          checked={!visibleQuestions?.includes('4')}
                        />
                      </div>
                    </div>
                  </div>

                  {/*QUESTION 4*/}
                  {visibleQuestions.includes('4') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Hoeveel kinderen wonen thuis?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Select
                          ariaLabel={'child-amount'}
                          form={''}
                          name={'child-amount'}
                          value={childAmount.length.toString()}
                          options={['1', '2', '3', '4', '5', '6']}
                          onChange={(event) => setChildAmount(Array.from({ length: event.target.value as unknown as number }, (v, i) => i))}
                        />
                      </div>
                    </div>
                  }

                  {/*QUESTION 5*/}
                  <div className="input-container">
                    {visibleQuestions.includes('4') && childAmount.length > 0 &&
                      <div className="input-wrapper input-wrapper__input">
                        {childAmount.map((field, index) =>
                          <div className={'question'} key={`child-${index}`}>
                            {/*LABEL*/}
                            <p className={'label'}>{`Leeftijd kind ${index + 1}`}</p>

                            {/*CONTROL*/}
                            <div className={'control-wrapper'}>
                              <Input
                                ariaLabel={'age'}
                                form={''}
                                name={'age'}
                                value={handlePersonValue(index + 2)}
                                onChange={(event) => handlePersonValueChange(index + 2, event.target.value as unknown as number)}
                              />
                            </div>
                          </div>,
                        )}
                      </div>}
                  </div>

                  {/*BUTTON - CONTINUE*/}
                  <div className={'container-button'}>
                    <Button
                      ariaLabel={'continue'}
                      form={''}
                      disabled={
                        handlePersonValue(0) == '0'
                        || Number(handlePersonValue(0)) < 18
                        || Number(handlePersonValue(0)) > 67
                        || (visibleQuestions.includes('2b') && handlePersonValue(1) === '0')
                      }
                      name={'continue'}
                      variant={'primary'}
                      onClick={() => handleSavePeople()}
                    >
                      Naar inkomen en lasten
                    </Button>
                  </div>
                </Accordion>
              </div>

              {/*FINANCE QUESTIONS*/}
              <div className={'container-accordion'}>
                <Accordion
                  header={<h4 className={'accordion-header'}>Inkomen en lasten</h4>}
                  hideBorder={true}
                  onHeaderClick={() => {
                    if (peopleSaved
                      && ((handlePersonValue(0) !== '0' && Number(handlePersonValue(0)) >= 18 && Number(handlePersonValue(0)) <= 67)
                        || (visibleQuestions.includes('2b') && handlePersonValue(1) !== '0'))) {
                      setAccordionOpen({
                        incomes: !accordionOpen.incomes,
                        personal: false,
                        expenses: false,
                      });
                    }
                  }}
                  open={accordionOpen.incomes}
                >
                  {/*QUESTION 6a*/}
                  <div className={'question'}>
                    {/*LABEL*/}
                    <p className={'label'}>Wat is je netto inkomen per maand?</p>

                    {/*CONTROL*/}
                    <div className={'control-wrapper'}>
                      <Input
                        ariaLabel={'income'}
                        form={''}
                        name={'income'}
                        startAdornment={'€'}
                        value={nibudValues.Incomes[0].MonthlyIncome.Value}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (isNaN(Number(value)) || Number(value) < 0) {
                            setIncomeValid(false);
                            return;
                          } else if (value === '' || value === undefined) {
                            setIncomeValid(false);
                          } else {
                            setIncomeValid(true);
                          }

                          let tempIncomes = [...nibudValues.Incomes];
                          tempIncomes[0].MonthlyIncome.Value = value;
                          tempIncomes[0].YearlyIncomes[0].Value = Number(value) * 12;

                          setNibidValues({
                            ...nibudValues,
                            Incomes: tempIncomes,
                          });
                        }}
                      />

                      {/*VALIDATION*/}
                      <div>
                        <p className={'error-massage'}>
                          {!isIncomeValid && 'Het totaal inkomen moet meer zijn dan €0'}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*QUESTION 6b*/}
                  {visibleQuestions.includes('6b') && hasPartner &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Wat is het netto inkomen van je partner per maand?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Input
                          ariaLabel={'income'}
                          form={''}
                          name={'income'}
                          startAdornment={'€'}
                          value={nibudValues?.Incomes?.[1]?.MonthlyIncome?.Value ?? ''}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (isNaN(Number(value)) || Number(value) < 0) {
                              setIncomeValid(false);
                              return;
                            } else if (value === '' || value === undefined) {
                              setIncomeValid(false);
                            } else {
                              setIncomeValid(true);
                            }

                            let tempIncomes = [...nibudValues.Incomes];

                            if (tempIncomes.length < 2) {
                              const tempIncome = {
                                Owner: OwnerTypes.Partner,
                                Type: 'Employed',
                                MonthlyIncome: { Value: 0 },
                                YearlyIncomes: [{ Value: 0 }],
                                ApplicationID: localStorage.getItem('applicationId')?.toString() ?? '',
                              };

                              tempIncomes.push(tempIncome as any); // FIXME: Update to CC interface once implemented.
                            }

                            tempIncomes[1].MonthlyIncome.Value = value;
                            tempIncomes[1].YearlyIncomes[0].Value = Number(value) * 12;

                            setNibidValues({
                              ...nibudValues,
                              Incomes: tempIncomes,
                            });
                          }}
                        />

                        {/*VALIDATION*/}
                        <div>
                          <p className={'error-massage'}>
                            {!isIncomeValid && 'Het totaal inkomen moet meer zijn dan €0'}
                          </p>
                        </div>
                      </div>
                    </div>
                  }

                  {/*QUESTION 7*/}
                  <div className={'question'}>
                    {/*LABEL*/}
                    <p className={'label'}>Heb {showPluralWordIfApplicable("je")} een koop- of huurhuis?</p>

                    {/*CONTROL*/}
                    <div className={'control-wrapper control-wrapper__radio'}>
                      <div className={'radio-wrapper'}>
                        <label>Koop</label>
                        <Radio
                          ariaLabel={'radio-koop'}
                          name={'radio-koop'}
                          onClick={() => {
                            let tempFieldVisibility = [...visibleQuestions];
                            let filtered = tempFieldVisibility.filter(field => field !== '9');

                            filtered.push('8');
                            setVisibleQuestions(filtered);
                          }}
                          checked={visibleQuestions?.includes('8')}
                        />
                      </div>

                      <div className={'radio-wrapper'}>
                        <label>Huur</label>
                        <Radio
                          ariaLabel={'radio-huur'}
                          name={'radio-huur'}
                          onClick={() => {
                            let tempFieldVisibility = [...visibleQuestions];
                            let filtered = tempFieldVisibility.filter(field => field !== '8');

                            filtered.push('9');
                            setVisibleQuestions(filtered);
                          }}
                          checked={visibleQuestions?.includes('9') && !visibleQuestions?.includes('8')}
                        />
                      </div>
                    </div>
                  </div>

                  {/*QUESTION 8a*/}
                  {visibleQuestions.includes('8') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Wat voor huis is het?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Select
                          ariaLabel={'property-type'}
                          form={''}
                          name={'property-type'}
                          optionValue={'value'}
                          optionText={'label'}
                          value={nibudValues?.RealEstates?.[0]?.Type.toString() ?? ''}
                          options={[
                            { label: 'Appartement', value: 'Apartment' },
                            { label: 'Tussenwoning', value: 'FamilyHouse' },
                            { label: 'Hoekwoning', value: 'HouseBoat' },
                            { label: 'Vrijstaand', value: 'FamilyHouseWithGarage' },
                          ]}
                          onChange={(event) => {
                            let tempRealEstate = [...nibudValues.RealEstates];
                            tempRealEstate[0].Type = event.target.value;

                            setNibidValues({
                              ...nibudValues,
                              RealEstates: tempRealEstate,
                            });
                          }}
                        />
                      </div>
                    </div>
                  }

                  {/*QUESTION 8b*/}
                  {visibleQuestions.includes('8') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Bouwjaar</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Select
                          ariaLabel={'property-age'}
                          form={''}
                          name={'property-age'}
                          value={
                            nibudValues?.RealEstates?.[0]?.YearOfConstruction <= 1965
                              ?
                              'voor 1965'
                              :
                              nibudValues?.RealEstates?.[0]?.YearOfConstruction <= 1988
                                ?
                                '1966-1988'
                                :
                                nibudValues?.RealEstates?.[0]?.YearOfConstruction <= 2000
                                  ?
                                  '1989-2000'
                                  :
                                  'na 2000'
                          }
                          options={['voor 1965', '1966-1988', '1989-2000', 'na 2000']}
                          onChange={(event) => {
                            let tempRealEstate = [...nibudValues.RealEstates];

                            switch (event.target.value) {
                              case 'voor 1965':
                                tempRealEstate[0].YearOfConstruction = 1965;
                                break;
                              case '1966-1988':
                                tempRealEstate[0].YearOfConstruction = 1988;
                                break;
                              case '1989-2000':
                                tempRealEstate[0].YearOfConstruction = 2000;
                                break;
                              case 'na 2000':
                                tempRealEstate[0].YearOfConstruction = 2001;
                                break;
                              default:
                            }

                            setNibidValues({
                              ...nibudValues,
                              RealEstates: tempRealEstate,
                            });
                          }}
                        />
                      </div>
                    </div>
                  }

                  {/*QUESTION 8c*/}
                  {visibleQuestions.includes('8') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>WOZ-waarde</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Select
                          ariaLabel={'property-age'}
                          form={''}
                          name={'property-age'}
                          value={
                            nibudValues?.RealEstates?.[0]?.WOZWaarde < 200000
                              ?
                              '< 200 000'
                              :
                              nibudValues?.RealEstates?.[0]?.WOZWaarde < 350000
                                ?
                                '200 000 - 350 000'
                                :
                                nibudValues?.RealEstates?.[0]?.WOZWaarde < 500000
                                  ?
                                  '350 000 - 500 000'
                                  :
                                  '> 500 000'
                          }
                          options={['< 200 000', '200 000 - 350 000', '350 000 - 500 000', '> 500 000']}
                          onChange={(event) => {
                            let tempRealEstate = [...nibudValues.RealEstates];

                            switch (event.target.value) {
                              case '< 200 000':
                                tempRealEstate[0].WOZWaarde = 199999;
                                break;
                              case '200 000 - 350 000':
                                tempRealEstate[0].WOZWaarde = 349999;
                                break;
                              case '350 000 - 500 000':
                                tempRealEstate[0].WOZWaarde = 499999;
                                break;
                              case '> 500 000':
                                tempRealEstate[0].WOZWaarde = 500001;
                                break;
                              default:
                            }

                            setNibidValues({
                              ...nibudValues,
                              RealEstates: tempRealEstate,
                            });
                          }}
                        />
                      </div>
                    </div>
                  }

                  {/*QUESTION 8d*/}
                  {visibleQuestions.includes('8') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Wat is de hypotheek per maand?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Input
                          ariaLabel={'hypotheek'}
                          form={''}
                          name={'hypotheek'}
                          startAdornment={'€'}
                          value={nibudValues?.Expenses?.[0]?.Amount}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (isNaN(Number(value)) && Number(value) < 0) {
                              setMortgageValid(false);
                              return;
                            } else if (value === '' || value === undefined) {
                              setMortgageValid(false);
                            } else {
                              setMortgageValid(true);
                            }

                            let tempExpenses = [...nibudValues.Expenses];
                            tempExpenses[0].Amount = value;

                            setNibidValues({
                              ...nibudValues,
                              Expenses: tempExpenses,
                            });
                          }}
                        />

                        {/*VALIDATION*/}
                        <div>
                          <p className={'error-massage'}>
                            {!isMortgageValid && 'Het bedrag moet meer zijn dan €0 zijn'}
                          </p>
                        </div>
                      </div>
                    </div>
                  }

                  {/*QUESTION 9*/}
                  {visibleQuestions.includes('9') &&
                    <div className={'question'}>
                      {/*LABEL*/}
                      <p className={'label'}>Wat is de huur per maand?</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Input
                          ariaLabel={'huur'}
                          form={''}
                          name={'huur'}
                          startAdornment={'€'}
                          value={nibudValues?.Expenses?.[0]?.Amount}
                          onChange={(event) => {
                            const value = event.target.value;
                            if (isNaN(Number(value)) || Number(value) < 0) {
                              setRentValid(false);
                              return;
                            } else if (value === '' || value === undefined) {
                              setRentValid(false);
                            } else {
                              setRentValid(true);
                            }

                            let tempExpenses = [...nibudValues.Expenses];
                            tempExpenses[0].Amount = value;

                            setNibidValues({
                              ...nibudValues,
                              Expenses: tempExpenses,
                            });
                          }}
                        />

                        {/*VALIDATION*/}
                        <div>
                          <p className={'error-massage'}>
                            {!isRentValid && 'Het bedrag moet meer zijn dan €0 zijn'}
                          </p>
                        </div>
                      </div>
                    </div>
                  }

                  {/*BUTTONS*/}
                  <div className={'container-buttons'}>
                    {/*BACK*/}
                    <div className={'container-button'}>
                      <Button
                        ariaLabel={'continue'}
                        form={''}
                        name={'continue'}
                        variant={'secondary'}
                        onClick={() => {
                          setAccordionOpen({
                            incomes: false,
                            personal: !accordionOpen.personal,
                            expenses: false,
                          });
                        }}
                      >
                        Vorige
                      </Button>
                    </div>

                    {/*NEXT*/}
                    <div className={'container-button'}>
                      <Button
                        ariaLabel={'continue'}
                        form={''}
                        disabled={
                          nibudValues.Incomes[0].MonthlyIncome.Value == 0
                          || nibudValues?.Expenses?.[0]?.Amount == 0
                        }
                        name={'continue'}
                        variant={'primary'}
                        onClick={() => {
                          setLoading(true);
                          handleSaveIncomesExpenses();
                        }}
                      >
                        Naar overige uitgaven
                      </Button>
                    </div>
                  </div>
                </Accordion>
              </div>

              {/*ESTIMATE*/}
              <div className={'container-accordion'}>
                <Accordion
                  header={<h4 className={'accordion-header'}>Overige uitgaven (schatting)</h4>}
                  hideBorder={true}
                  onHeaderClick={() => {
                    if (nibudValues.Incomes[0].MonthlyIncome.Value !== 0
                      && nibudValues?.Expenses?.[0]?.Amount !== 0
                    ) {
                      setAccordionOpen({
                        expenses: !accordionOpen.expenses,
                        incomes: false,
                        personal: false,
                      });
                    }
                  }}
                  open={accordionOpen.expenses}
                >
                  {nibudExpenses.map((expense, index) => (
                    <div className={'question'} key={`nibud-expense-${index}`}>
                      {/*LABEL*/}
                      <p className={'label'}>{expense.Description ?? ''}</p>

                      {/*CONTROL*/}
                      <div className={'control-wrapper'}>
                        <Input
                          ariaLabel={expense.Type}
                          form={''}
                          name={expense.Type}
                          startAdornment={'€'}
                          endAdornment={'p/m'}
                          value={expense.Amount}
                          onChange={(event) => {
                            let tempNibudExpenses = [...nibudExpenses];

                            tempNibudExpenses[index].Amount = event.target.value.toString();
                            setNibudExpenses(tempNibudExpenses);
                          }}
                        />
                      </div>
                    </div>
                  ))
                  }
                </Accordion>
              </div>

              {/*ACTIONS*/}
              <div className={'actions'}>
                {/*BUTTON - BACK*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-back'}
                    form={''}
                    name={'back'}
                    variant={'secondary'}
                    onClick={() => setActivePage(Pages.Estimate)}
                  >
                    Vorige
                  </Button>
                </div>

                {/*BUTTON - START*/}
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-start'}
                    form={''}
                    name={'start'}
                    disabled={
                      handlePersonValue(0) == '0'
                      || (visibleQuestions.includes('2b') && handlePersonValue(1) === '0')
                      || nibudValues.Incomes[0].MonthlyIncome.Value == 0
                      || nibudValues?.Expenses?.[0]?.Amount == 0
                    }
                    variant={'primary'}
                    onClick={() => updateNibudExpenses()}
                  >
                    Volgende
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {/*MODAL*/}
        {modalOpen &&
          <div
            className={'backdrop'}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setModalOpen(false);
            }}
          >
            <div
              className={'modal'}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setModalOpen(true);
              }}
            >
              {/*PHONE*/}
              <div className={'container-image'}>
                <img src={Phone} alt={''} className={'image'} />
              </div>

              {/*TITLE*/}
              <h2 className={'title'}>Waarom gebruik maken van transactiedata?</h2>

              {/*LIST*/}
              <div className={'container-list'}>
                <div className={'item'}>
                  <div className={'check-container'}>
                    <CheckCircle />
                  </div>

                  <p className={'description'}>Op deze manier kijk {showPluralWordIfApplicable("je")} niet alleen
                    naar {showPluralWordIfApplicable("je")} inkomen, maar ook naar {showPluralWordIfApplicable("je")} 
                    levensstijl en uitgavenpatroon</p>
                </div>

                <div className={'item'}>
                  <div className={'check-container'}>
                    <CheckCircle />
                  </div>

                  <p className={'description'}>Hoe past bijvoorbeeld een hypotheek binnen dit plaatje? En wat
                    kan {showPluralWordIfApplicable("je")} veilig per maand missen?
                    </p>
                </div>

                <div className={'item'}>
                  <div className={'check-container'}>
                    <CheckCircle />
                  </div>

                  <p className={'description'}>Bekijk waar jouw kansen liggen om er financieel op vooruit te gaan</p>
                </div>
              </div>

              <div className={'button-wrapper'}>
                <div className={'container-button'}>
                  <Button
                    ariaLabel={'button-back'}
                    form={''}
                    name={'back'}
                    variant={'primary'}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      setModalOpen(false);
                    }}
                  >
                    Oke, ik begrijp het
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/*LOADER*/}
      {loading &&
        <Loader
          text={'Een momentje...'}
        />
      }

      {/*SNACKBAR*/}
      {snackbarMessage &&
        <Snackbar
          alert={snackbarMessage}
          removeAlert={() => setSnackbarMessage(undefined)}
        />
      }
    </React.Fragment>
  );
};

export default Details;
