// React
import React from 'react';

// Styles
import './Select.scss';

// Interfaces
import * as INTERFACES from './SelectInterfaces';

export const Select: React.FC<INTERFACES.SelectProperties> = (props: INTERFACES.SelectProperties) => {
  return (
    <div id={'select'}>
      <div className={'wrapper'}>
        <select
          aria-label={props?.ariaLabel}
          aria-disabled={props?.disabled ?? false}
          className={`${props?.className + ' ' ?? undefined}select ${props.disabled ? 'select--disabled' : undefined}`}
          data-testid={`${props?.form}-${props.name}`}
          form={props?.form}
          multiple={props?.multiple ?? false}
          name={props?.name}
          required={props?.required ?? false}
          size={props?.size}
          value={props.value}
          onBlur={props?.onBlur ?? undefined}
          onChange={!props?.disabled ? props?.onChange : () => null}
          onFocus={props?.onFocus ?? undefined}
          onKeyUp={props?.onKeyUp ?? undefined}
          onKeyDown={props?.onKeyDown ?? undefined}
        >

          {
            props.emptyOption &&
            <option
              disabled
              value={''}
            >
              Please select
            </option>
          }

          {
            props.options.map((option, index) => (
              <option
                key={index}
                value={
                  props.optionValue
                    ?
                    props.optionValue === 'index'
                      ?
                      index
                      :
                      option[props.optionValue]
                    :
                    option
                }
              >
                {props.optionText ? option[props.optionText] : option}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  );
};

