// Created By: Johan
// Created On: 12/10/2021

// React
import React, { useEffect, useState } from 'react';

// Styles
import './Rapport.scss';

// Images
import Logo from '../../../assets/images/logo-beyond.png';
import { ReactComponent as Salary } from '../../../assets/icons/salary.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Leisure } from '../../../assets/icons/leisure.svg';

// Interfaces
import { RapportProperties } from './RapportInterfaces';
import { Budget } from '../options/steps/calculations/CalculationsInterfaces';

const Rapport: React.FC<RapportProperties> = () => {
  // State
  const [budget, setBudget] = useState<Budget>();

  // Effects
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);

    scrubData(JSON.parse(atob(params?.get('data') as string)) as unknown as Record<string, unknown>);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const scrubData = (result: Record<string, any>) => {
    const tempResultExpenses = [...result.FixedAndEssentialExpenses].concat([...result.HousingExpenses])
    tempResultExpenses.push(result.LeisureExpense)

    // Strip out only incomes, expenses and assets
    let tempAssets = [...[] as Array<Record<string, unknown>>];
    let tempExpenses = tempResultExpenses as Array<Record<string, string | number | undefined>>;
    let tempIncomes = [...result.Incomes as Array<Record<string, unknown>>];
    let filteredAssets = [];
    let filteredExpenses = [];
    let filteredLeisure = [];
    let filteredIncomes = [];
    let incomesTotal: number = 0;
    let expensesTotal: number = 0;
    let assetsTotal: number = 0;
    let leisureTotal: number = 0;
    let tempEmployedCount = 1;

    // Strip out only type and value from assets
    for (const asset of tempAssets) {
      if (asset.Value === 0) continue;

      let tempAsset;

      // Create label based on type
      switch (asset.Type) {
        case 'SavingsAccount':
          tempAsset = { id: asset.ID, label: 'Sparen', value: asset.Value };
          break;
        case 'MutualFund':
          tempAsset = { id: asset.ID, label: 'Beleggen', value: asset.Value };
          break;
        default:
          continue;
      }

      filteredAssets.push(tempAsset);
      let amount = asset.Value as number;
      assetsTotal = assetsTotal + amount;
    }

    // Strip out only type and value from expenses
    for (const expense of tempExpenses) {
      if (expense.Amount === 0) continue;

      let tempExpense;

      // Create label based on type
      switch (expense.Type) {
        case 'UtilityCosts':
          tempExpense = { id: expense.ID, label: 'Woonlasten', value: expense.Amount };
          break;
        case 'TelecomCredit':
          tempExpense = { id: expense.ID, label: 'Telefoon, televisie en internet', value: expense.Amount };
          break;
        case 'Insurances':
          tempExpense = { id: expense.ID, label: 'Verzekeringen', value: expense.Amount };
          break;
        case 'Vervoer':
          tempExpense = { id: expense.ID, label: 'Reiskosten', value: expense.Amount };
          break;
        case 'BoodschappenHuishoudgeld':
          tempExpense = { id: expense.ID, label: 'Voeding', value: expense.Amount };
          break;
        case 'HouseholdExpense':
          tempExpense = {
            id: expense.ID,
            label: 'Gezondheid, verzorging en huishoudelijke uitgaven',
            value: expense.Amount,
          };
          break;
        case 'Subscriptions':
          tempExpense = { id: expense.ID, label: 'Abonnementen en contributies', value: expense.Amount };
          break;
        case 'OverigeVasteLasten':
          tempExpense = { id: expense.ID, label: 'Persoonlijk onvermijdbare lasten', value: expense.Amount };
          break;
        case 'OnregelmatigeUitgaven':
          tempExpense = { id: expense.ID, label: 'Overige onregelmatige lasten', value: expense.Amount };
          break;
        case 'Vakantie/vrije tijd':
          filteredLeisure.push({ id: expense.ID, label: 'Vakantie vrije tijd', value: expense.Amount });

          let leisureAmount1 = expense.Amount as number;
          leisureTotal = leisureTotal + leisureAmount1;
          break;
        case 'Vakantievrijetijd':
          filteredLeisure.push({ id: expense.ID, label: 'Vakantie vrije tijd', value: expense.Amount });

          let leisureAmount2 = expense.Amount as number;
          leisureTotal = leisureTotal + leisureAmount2;
          break;
        case 'Woning':
          tempExpense = { id: expense.ID, label: 'Hypotheek', value: expense.Amount };
          break;
        case 'Rent':
          tempExpense = { id: expense.ID, label: 'Huur', value: expense.Amount };
          break;
        case 'Miscellaneous':
          tempExpense = { id: expense.ID, label: 'Woonverzekeringen', value: expense.Amount };
          break;
        case 'InventoryHome':
          tempExpense = { id: expense.ID, label: 'Onderhoud', value: expense.Amount };
          break;
        default:
          continue;
      }

      if (tempExpense) {
        filteredExpenses.push(tempExpense);
        let amount = expense.Amount as number;

        expensesTotal = expensesTotal + amount;
      }
    }

    // Strip out only type and value from incomes
    for (const income of tempIncomes) {
      let tempMonthlyIncome: Record<string, unknown> = income.Amount as Record<string, unknown>;

      if (tempMonthlyIncome?.Value === 0) continue;

      let tempIncome;

      // Create label based on type
      switch (income.Type) {
        case 'Employed':
          tempIncome = { id: income.ID, label: `Inkomen ${tempEmployedCount}`, value: tempMonthlyIncome };
          tempEmployedCount = tempEmployedCount + 1;

          const amount = income.Amount as number;
          incomesTotal = incomesTotal + amount;
          break;
        case 'OtherTaxableIncome':
          tempIncome = { id: income.ID, label: 'Onregelmatig inkomen', value: tempMonthlyIncome?.Value };
          break;
        case 'Permanent':
          tempIncome = { id: income.ID, label: 'Je inkomen  (NBI)', value: tempMonthlyIncome?.Value };
          break;
        case 'Allowance':
          tempIncome = { id: income.ID, label: 'Consumptief besteedbaar inkomen', value: tempMonthlyIncome?.Value };
          break;
        case 'OtherNonTaxableIncome':
          tempIncome = { id: income.ID, label: 'Werkelijk besteedbaar inkomen', value: tempMonthlyIncome?.Value };
          break;
        default:
          continue;
      }

      filteredIncomes.push(tempIncome);
    }

    setBudget({
      assetTotal: assetsTotal,
      expensesTotal: expensesTotal,
      incomesTotal: incomesTotal,
      leisureTotal: leisureTotal,
      netto: (incomesTotal - (expensesTotal + leisureTotal)),
      incomes: filteredIncomes,
      expenses: filteredExpenses,
      assets: filteredAssets,
      leisure: filteredLeisure,
    });
  };

  return (
    <div id={'rapport'}>
      <div className={'rapport'}>
        {/*LOGO*/}
        <img src={Logo} alt={'logo'} className={'logo'} />

        {/*SUB HEADER*/}
        <h2 className={'main-subheader'}>Jouw netto besteedbaar inkomen</h2>

        {/*CONTAINER*/}
        {budget &&
          <div className={'container'}>
            {/*HEADER*/}
            <h3 className={'header'}>Wat houd je gemiddeld over?</h3>

            {/*NETTO*/}
            <h2 className={'netto'}>{budget?.netto >= 0 ? '' : '- '}{'€ '}{Math.abs(budget?.netto ?? 0)} <span>netto per maand</span></h2>

            {/*INCOMES*/}
            <div className={'incomes'}>
              {/*ICON*/}
              <div className={'icon icon__income'}>
                <Salary />
              </div>

              {/*BREAKDOWN*/}
              <div className={'breakdown'}>
                {/*TOTAL*/}
                <div className={'total'}>
                  {/*TITLE*/}
                  <p className={'title'}>Je inkomen</p>

                  {/*VALUE*/}
                  <p className={'value value__total'}><span>+</span>{`€ ${budget?.incomesTotal ?? '0'} per maand`}</p>
                </div>

                {/*INCOMES*/}
                {budget?.incomes && budget.incomes.map((income, index) => (
                  <div className={'item'} key={`income-${index}`}>
                    <p className={'label'}>{`${income?.label}` ?? ''}</p>
                    <p className={'value'}><span>+</span>{`€ ${income?.value} per maand` ?? '0 per maand'}</p>
                  </div>
                ))}
              </div>
            </div>

            {/*EXPENSES*/}
            <div className={'expenses'}>
              {/*ICON*/}
              <div className={'icon icon__expenses'}>
                <Home />
              </div>

              {/*BREAKDOWN*/}
              <div className={'breakdown'}>
                {/*TOTAL*/}
                <div className={'total'}>
                  {/*TITLE*/}
                  <p className={'title'}>Vaste en essentiële lasten</p>

                  {/*VALUE*/}
                  <p className={'value value__total'}><span>-</span>{`€ ${budget?.expensesTotal ?? '0'} per maand`}</p>
                </div>

                {/*INCOMES*/}
                {budget?.expenses && budget.expenses.map((expense, index) => (
                  <div className={'item'} key={`expense-${index}`}>
                    <p className={'label'}>{`${expense?.label}` ?? ''}</p>
                    <p className={'value'}><span>-</span>{`€ ${expense?.value} per maand` ?? '0 per maand'}</p>
                  </div>
                ))}
              </div>
            </div>

            {/*VRIJETIJDSUITGAVEN*/}
            <div className={'leisure'}>
              {/*ICON*/}
              <div className={'icon icon__leisure'}>
                <Leisure />
              </div>

              {/*BREAKDOWN*/}
              <div className={'breakdown'}>
                {/*TOTAL*/}
                <div className={'total'}>
                  {/*TITLE*/}
                  <p className={'title'}>Vrijetijdsuitgaven</p>

                  {/*VALUE*/}
                  <p className={'value value__total'}><span>-</span>{`€ ${budget?.leisureTotal ?? '0'} per maand`}</p>
                </div>
              </div>
            </div>

            {/*NETTO*/}
            <div className={'section3'}>
              <p className={'title'}>Wat houd je netto over?</p>
              <p className={'value'}>
                <span>{budget?.netto >= 0 ? '' : '-'}</span>{`€ ${Math.abs(budget?.netto ?? 0)} per maand`}</p>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Rapport;
