// React
import React, {useState} from 'react';

// Styles
import './Download.scss';

// Interfaces
import {DownloadProperties} from './DownloadInterfaces';
import {Alert} from '../../../../../components/feedback/snackbar/SnackbarInterfaces';

// Images
import {ReactComponent as Party} from '../../../../../assets/images/party.svg';
import {ReactComponent as Check} from '../../../../../assets/icons/check.svg';
import {ReactComponent as Pdf} from '../../../../../assets/icons/pdf.svg';
import {ReactComponent as Reload} from '../../../../../assets/icons/reload.svg';
import {ReactComponent as DownloadIcon} from '../../../../../assets/icons/download.svg';
import {ReactComponent as Person} from '../../../../../assets/icons/person.svg';
import {ReactComponent as Email} from '../../../../../assets/icons/email.svg';

// Components
import Loader from '../../../../../components/feedback/loader/Loader';
import Snackbar from '../../../../../components/feedback/snackbar/Snackbar';
import Button from '../../../../../components/controls/button/Button';
import {ServiceHelper} from '../../../../../middleware/serviceHelper/ServiceHelper';
import {APITypes, GlobalState, Pages} from '../../../../../middleware/enums/Enums';

// Routes
import {useNavigate, useLocation} from 'react-router-dom';
import {useGlobalState} from '../../../../../middleware/hooks/Hooks';
import {SingularToPlural} from '../../../../../helpers/singularToPlural';

const Download: React.FC<DownloadProperties> = (props: DownloadProperties): JSX.Element => {
  // States
  const [activePage, setActivePage] = useState<string>(Pages.Download);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert>();
  const [hasPartner] = useGlobalState(GlobalState.HAS_PARTNER);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Handlers
  const handleDownload = () => {
    setLoading(true);
    let applicationId = localStorage.getItem('applicationId');

    ServiceHelper
      .get({
        api: APITypes.INVITATIONS,
        url: `/userinsight/generatepdf/${applicationId}`,
      })
      .then((result) => {
        const linkSource = `data:application/pdf;base64,${result.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "Mijn Besteedbaar Inkomen Rapport.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
        setDownloaded(true);
      });
  };

  const navigateAndLogout = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('invitationId');

    navigate(`/landing`, {replace: true, state: {prevPath: location.pathname}});
  }

  const showPluralWordIfApplicable = (string: string): string => {
    return hasPartner ? SingularToPlural.convert(string) : string;
  }

  return (
    <React.Fragment>
      <div className={`download${loading ? ' download__disable-overflow' : ''}`}>

        {/*DOWNLOAD*/}
        {activePage === Pages.Download &&
          <div className={'download-container'}>
            {/*HEADER*/}
            <h3 className={'header'}>Download {showPluralWordIfApplicable("je")} resultaat</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*IMAGE*/}
              <div className={'image-wrapper'}>
                <Party/>
              </div>

              {/*HEADER*/}
              <h4 className={'header'}>
                {
                  downloaded
                    ?
                    <React.Fragment>
                      Gelukt! <Check/>
                    </React.Fragment>
                    :
                    (showPluralWordIfApplicable("Je") + ' overzicht staat voor ' + showPluralWordIfApplicable("je") + ' klaar')
                }
              </h4>

              {/*SUBHEADER*/}
              <p className={'subheader'}>
                {
                  downloaded
                    ?
                    'Het overzicht is succesvol gedownload'
                    :
                    'Download de pdf en deel deze met de adviseur'
                }
              </p>

              {/*PDF*/}
              <div className={'pdf-container'}>
                {/*NAME*/}
                <p className={'filename'}>
                  <Pdf/> {showPluralWordIfApplicable("Mijn")} Besteedbaar Inkomen Rapport.pdf
                </p>

                {/*STATUS*/}
                {downloaded &&
                  <p className={'status'}>gedownload <Check/></p>
                }
              </div>

              {/*DOWNLOAD AGAIN*/}
              {downloaded &&
                <div className={'reload-wrapper'}>
                  <p className={'reload'}>
                    <Reload className={'reload__content'}/>
                    <a className={'reload__content'} onClick={() => {handleDownload();}}>
                      Opnieuw downloaden
                    </a>
                  </p>
                </div>
              }

              {/*BUTTONS*/}
              <div className={'buttons-container'}>
                {/*VORIGE*/}
                <div className={'button-wrapper'}>
                  <Button
                    ariaLabel={'vorige'}
                    form={''}
                    name={'vorige'}
                    variant={'secondary'}
                    onClick={() => props.goPreviousStep()}
                  >
                    Vorige
                  </Button>
                </div>

                {/*VOLGENDE*/}
                <div className={'button-wrapper'}>
                  <Button
                    ariaLabel={'volgende'}
                    form={''}
                    name={'volgende'}
                    variant={'primary'}
                    onClick={() => downloaded ? setActivePage('bedankt') : handleDownload()}
                  >
                    {
                      downloaded
                        ?
                        'Volgende'
                        :
                        <div className={'download-button-content'}>
                          <DownloadIcon/> Download
                        </div>
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

        {/*BEDANKT*/}
        {activePage === Pages.Thanks &&
          <div className={'bedankt'}>
            {/*HEADER*/}
            <h3 className={'header'}>Bedankt!</h3>

            {/*CONTAINER*/}
            <div className={'container'}>
              {/*HEADER*/}
              <h4 className={'header'}>Wat {showPluralWordIfApplicable("kun")} {showPluralWordIfApplicable("je")} nog
                doen?</h4>

              {/*CARD CONTAINER*/}
              <div className={'container-card'}>
                {/*CARD 1*/}
                <div className={'card'}>
                  {/*ICON*/}
                  <div className={'container-icon'}>
                    <Person/>
                  </div>

                  {/*HEADER*/}
                  <p
                    className={'card-header'}>{showPluralWordIfApplicable("Je")} {showPluralWordIfApplicable("kunt")} je
                    pdf met {showPluralWordIfApplicable("je")} adviseur delen</p>

                  {/*DESCRIPTION*/}
                  <p className={'card-description'}>{showPluralWordIfApplicable("Je")} adviseur kan het inzicht
                    gebruiken voor bijvoorbeeld een beter
                    hypotheekadvies</p>
                </div>

                {/*LINE*/}
                <div className={'line'}/>

                {/*CARD 2*/}
                <div className={'card'}>
                  {/*ICON*/}
                  <div className={'container-icon'}>
                    <Email/>
                  </div>

                  {/*HEADER*/}
                  <p className={'card-header'}>Meer grip op {showPluralWordIfApplicable("je")} bestedingspatroon</p>

                  {/*DESCRIPTION*/}
                  <p
                    className={'card-description'}>Zo {showPluralWordIfApplicable("kun")} {showPluralWordIfApplicable("je")} besparen
                    op specifieke uitgaves of juist zien waar de ruimte ligt</p>
                </div>
              </div>

              {/*BUTTON CONTAINER*/}
              <div className={'container-button'}>
                <div className={'button-wrapper'}>
                  <Button
                    ariaLabel={'afsluiten'}
                    form={''}
                    name={'afsluiten'}
                    variant={'primary'}
                    onClick={() => navigateAndLogout()}
                  >
                    Afsluiten
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/*LOADER*/}
      {loading &&
        <Loader
          text={'Een momentje...'}
        />
      }

      {/*SNACKBAR*/}
      {alert &&
        <Snackbar
          alert={alert}
          removeAlert={() => setAlert(undefined)}
        />
      }
    </React.Fragment>
  );
};

export default Download;